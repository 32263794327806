import classNames from 'classnames'
import { forwardRef } from 'react'

const ProfileIcon = forwardRef(({
  as,
  className,
  icon,
  style,
  withShadow,
  ...props
}, ref) => {
  const Component = as || 'div'
  const withShadowClass = withShadow ? 'with-box-shadow' : ''
  const loadingIcon = icon === 'loading'
  const defaultIcon = icon === 'default'

  const defaultIconLoaded = defaultIcon ? 'loaded' : ''
  const iconStyle = !defaultIcon && !loadingIcon ? {
    backgroundColor: 'transparent',
    backgroundImage: `url(${icon})`,
    backgroundSize: 'contain',
    ...style
  } : ''

  const IconComponent = defaultIcon ? 'img' : 'div'
  const defaultIconComponentAttrs = defaultIcon ? {src: '/assets/app_icon_white.svg'} : {}

  const classes = classNames(
    className, 'icon-element', withShadowClass, defaultIconLoaded
  )
  return (
    <Component ref={ref} className={classes} {...props} style={iconStyle}>
      <IconComponent {...defaultIconComponentAttrs} className="d-flex icon" />
      {props.children}
    </Component>
  )
})

export default ProfileIcon
