import { createReduxStore, Uploader, Viewer, Manager, Auth } from 'encl-core'

import store from '@/stores'
import { defaultTitle } from '@/stores/uploader'

export { types, getSupported, hasStorage } from 'encl-core'

export const getBaseURL = () => {
  try {
    if (process.env.SERVER_URL) return process.env.SERVER_URL
  } catch {}
  return location.origin
}

let currentAuth = new Auth({
  baseURL: getBaseURL(),
  store: createReduxStore(store, {
    setState: (payload) => ( { type: 'auth/setState', payload} ),
    mapState: (state) => state.auth
  }),
})
export const getCurrentAuth = () => currentAuth

export const getClient = () => getCurrentAuth()._client

let currentManager = null
export const getCurrentManager = () => {
  currentManager = new Manager({
    baseURL: getBaseURL(),
    store: createReduxStore(store, {
      setState: (payload) => ( { type: 'manager/setState', payload} ),
      mapState: (state) => state.manager
    }),
    getAuth: getCurrentAuth
  })
  return currentManager
}

let currentUploader = new Uploader({
  baseURL: getBaseURL(),
  store: createReduxStore(store),
  defaultTitle,
  getManager: getCurrentManager,
  getAuth: getCurrentAuth
})
export const getCurrentUploader = () => currentUploader

let currentViewer = null
export const getCurrentViewer = () => currentViewer
export const openViewer = (key, rawSecret) => {
  currentViewer = new Viewer(key, rawSecret, {
    baseURL: getBaseURL(),
    store: createReduxStore(store, {
      setState: (payload) => ( { type: 'viewer/setState', payload} ),
      mapState: (state) => state.viewer
    }),
    getManager: getCurrentManager,
    getAuth: getCurrentAuth
  })
  return currentViewer
}