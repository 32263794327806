import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { BlobUploader, blobStream, getFileType } from 'encl-core'

// core.js requires store which causing circular dependencies if using normal import
// Using async/await pattern to have getClient only on demand
// In general API client should not depends on other store
// TODO: Purge getClient out

const MAXIMUM_IMAGE_SIZE = 1048576 // 1 MiB size

export const fetchUserProfile = createAsyncThunk(
  'user/fetchUserProfile',
  async () => {
    const { getClient } = await import('@/core')
    return getClient().userProfile()
  }
)

const uploadUserImage = async (imageType, file) => {
  const { getClient } = await import('@/core')
  const client = await getClient()
  const blobUploader = new BlobUploader({
    requestUploadHandler: payload => client.uploadUserImageRequest(imageType, payload)
  })
  const filesize = file.size
  if (filesize > MAXIMUM_IMAGE_SIZE) {
    throw new Error('file size is too large')
  }
  const fileType = getFileType(file)
  if (!/image/.test(fileType)) {
    throw new Error('file type is not supported')
  }
  const stream = blobStream(file)
  return blobUploader.uploadStream(filesize, fileType, stream)
}

export const changeBackground = createAsyncThunk(
  'user/changeBackground',
  async (payload) => {
    try {
      let value = payload.value
      if (payload.file) {
        value = 'background'
        await uploadUserImage('background', payload.file)
      }
      const { getClient } = await import('@/core')
      await getClient().updateUser({ background: value })
    } catch (e) {
      console.error('error changing user background: ', e)
      throw new Error(e)
    }
  }
)

export const changeLogo = createAsyncThunk(
  'user/changeLogo',
  async (payload) => {
    try {
      let value = payload.value
      if (payload.file) {
        value = 'logo'
        await uploadUserImage('logo', payload.file)
      }
      const { getClient } = await import('@/core')
      await getClient().updateUser({ logo: value })
    } catch (e) {
      console.error('error changing user logo: ', e)
    }
  }
)

export const restoreBackgroundLogoToDefault = createAsyncThunk(
  'user/restoreBackgroundLogoToDefault',
  async () => {
    try {
      const { getClient } = await import('@/core')
      return await getClient().updateUser({
        background: 'default',
        logo: 'default'
      })
    } catch (e) {
      console.error('error restoring user background and logo to default: ', e)
    }
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userProfile: {
      logo: 'loading',
      logoPending: false,
      background: 'loading',
      backgroundPending: false,
      error: false
    },
    changeBackgroundShown: false,
  },
  reducers: {
    showChangeBackground: (state) => ({...state, changeBackgroundShown: true}),
    hideChangeBackground: (state) => ({...state, changeBackgroundShown: false})
  },
  extraReducers: {
    [fetchUserProfile.fulfilled]: (state, {payload}) => {
      Object.assign(state.userProfile, {
        logo: payload.logo,
        background: payload.background
      })
    },
    [fetchUserProfile.rejected]: (state) => {
      Object.assign(state.userProfile, {
        logo: 'default',
        background: 'default'
      })
    },
    [changeBackground.pending]: (state, {meta}) => {
      // Show background from local blob
      state.userProfile.background = URL.createObjectURL(meta.arg.file)
      state.userProfile.backgroundPending = true
      state.userProfile.error = false
    },
    [changeBackground.fulfilled]: (state) => {
      state.userProfile.backgroundPending = false
    },
    [changeBackground.rejected]: (state) => {
      state.userProfile.backgroundPending = false
      state.userProfile.error = true
    },
    [changeLogo.pending]: (state, {meta}) => {
      // Show logo from local blob
      state.userProfile.logo = URL.createObjectURL(meta.arg.file)
      state.userProfile.logoPending = true
      state.userProfile.error = false
    },
    [changeLogo.fulfilled]: (state) => {
      state.userProfile.logoPending = false
    },
    [changeLogo.rejected]: (state) => {
      state.userProfile.backgroundPending = false
      state.userProfile.error = true
    },
    [restoreBackgroundLogoToDefault.pending]: (state) => {
      state.userProfile.backgroundPending = true
      state.userProfile.logoPending = true
    },
    [restoreBackgroundLogoToDefault.fulfilled]: (state, {payload}) => {
      Object.assign(state.userProfile, {
        logo: payload.logo,
        background: payload.background
      })
      state.userProfile.backgroundPending = false
      state.userProfile.logoPending = false
    }
  }
})

export const { showChangeBackground, hideChangeBackground } = userSlice.actions

export const userSelector = state => state.user

export default userSlice.reducer
