import { createSlice, createSelector } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

export const managerSlice = createSlice({
  name: 'manager',
  initialState: {
    bundles: {},
    localActiveKeys: null,
    localExpiredKeys: null,
    userActiveKeys: null,
    userExpiredKeys: null,
    hasActiveLocalBundles: false,
  },
  reducers: {
    setState: (state, action) => ({...state, ...action.payload})
  },
})

export const { setState } = managerSlice.actions

const stateSelector = state => state.manager

// Derived state
export const managerSelector = createSelector(
  stateSelector,
  (state) => ({
    ...state,
    expiresAt: state.expiresAt ? dayjs(state.expiresAt) : null,
  })
)

export default managerSlice.reducer
