export class DefaultStore {
  constructor (initialState = {}) {
    this.state = { ...initialState }
  }

  getState () {
    return this.state
  }

  setState (state) {
    Object.assign(this.state, state)
  }
}

export function createDefaultStore () {
  return function (initialState) {
    return new DefaultStore(initialState)
  }
}

export class ReduxStore {
  constructor (initialState, store, opts = {}) {
    const defaults = {
      setState: (payload) => ({ type: 'uploader/setState', payload }),
      mapState: (state) => state.uploader
    }
    this.opts = { ...defaults, ...opts }
    this.store = store
    store.dispatch(this.opts.setState(initialState))
  }

  getState () {
    return this.opts.mapState(this.store.getState())
  }

  setState (state) {
    return this.store.dispatch(this.opts.setState(state))
  }
}

export function createReduxStore (store, opts = {}) {
  return function (initialState) {
    return new ReduxStore(initialState, store, opts)
  }
}
