import { useEffect } from 'react'
import { useLocation } from 'wouter'
import { useSelector, useDispatch } from 'react-redux'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import CallToActionLabel from '@/components/call-to-action-label'
import ProfileIcon from '@/components/profile-icon'

import { getCurrentAuth } from '@/core'
import { authSelector } from '@/stores/auth'
import { userSelector, fetchUserProfile, showChangeBackground } from '@/stores/user'
import { logEvent } from '@/utils'

import style from './style'

const Header = (props) => {
  const dispatch = useDispatch()
  const state = useSelector(authSelector)
  const userState = useSelector(userSelector)
  const { isAuthenticated } = state
  const [, setLocation] = useLocation()
  const username = getCurrentAuth().name()
  const logo = userState.userProfile.logo

  const signOut = async () => {
    await getCurrentAuth().removeAccessToken()
    setLocation('/')
  }
  const signIn = () => {
    logEvent('Encl_signInButton')
    setLocation('/auth')
  }
  const signUp = () => {
    setLocation(`${location.origin}${location.pathname}?pro=1${location.hash}`)
    logEvent("Encl_signUpPane")
  }
  const onChangeBackground = () => {
    dispatch(showChangeBackground({}))
  }

  useEffect(() => {
    dispatch(fetchUserProfile())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Row noGutters className={`align-items-center justify-content-between px-3 ${style['header']}`}>
      <Col xs="auto" className="pr-3">
        <a href="/">
          <img className={`${style['icon-full']} vertical-middle`} alt="" src="/assets/app_icon_full_blue.svg" />
        </a>
      </Col>
      <Col className="d-none d-sm-inline-block border-left pl-3">
        <Button as="a" href="/" variant="primary" className="font-weight-bold">Create Transfer</Button>
      </Col>
      <Col xs="auto">
        {props.isOwner &&
         <>
           <div className="d-none d-sm-inline-block">
             {isAuthenticated &&
              <Button variant="palegray" className="d-none d-sm-inline-block" as="a" href="/manager">
                My Transfers
              </Button>
             }
             {!isAuthenticated &&
              <>
                <Button as="a" variant="palegray" onClick={signIn}>Log In</Button>
                <Button as="a" variant="palegray" className="ml-2" onClick={signUp}>Get PRO</Button>
              </>
             }
           </div>
           {!isAuthenticated &&
            <Dropdown className="d-inline-block d-sm-none">
              <Dropdown.Toggle variant="link" id="dropdown" className="py-0 text-darkgray">
                <ProfileIcon className="d-inline-flex p-2" icon={logo} />
              </Dropdown.Toggle>
              <Dropdown.Menu align="right">
                <Dropdown.Item eventKey="1" onClick={signUp}>Change Background<CallToActionLabel /></Dropdown.Item>
                <Dropdown.Item eventKey="2" onClick={signIn}>Log In</Dropdown.Item>
                <Dropdown.Item eventKey="3" onClick={signUp}>Get PRO</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
           }
           {isAuthenticated &&
            <Dropdown className="d-inline-block">
              <Dropdown.Toggle variant="link" id="dropdown" className="py-0 text-darkgray">
                <ProfileIcon className="d-inline-flex p-2" icon={logo} />
              </Dropdown.Toggle>
              <Dropdown.Menu align="right">
                <Dropdown.ItemText className="font-weight-bold">{username}</Dropdown.ItemText>
                {username &&
                 <Dropdown.Divider />
                }
                {!props.createTransfer &&
                 <Dropdown.Item eventKey="2" className="d-sm-none" onClick={() => setLocation('/manager') }>My Transfers</Dropdown.Item>
                }
                {props.createTransfer &&
                 <Dropdown.Item eventKey="3" className="d-sm-none" onClick={() => setLocation('/') }>Create Transfer</Dropdown.Item>
                }
                <Dropdown.Divider className="d-sm-none" />
                <Dropdown.Item eventKey="1" onClick={onChangeBackground}>Change Background</Dropdown.Item>
                <Dropdown.Item eventKey="4" onClick={signOut}>Log out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
           }
         </>
        }
      </Col>
    </Row>
  )
}

export default Header
