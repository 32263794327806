import style from './style.scss'

const Background = (props) => {
  // TODO: Should also support colour
  const { background } = props
  // Filter out default string case
  const backgroundStyle = background && background !== 'default' && background !== 'loading' ? {
    backgroundImage: `url(${background})`
  } : ''

  return (
    <div className={style['page__background']} style={backgroundStyle} />
  )
}

export default Background
