import { Helmet as DefaultHelmet } from 'react-helmet'

const Helmet = (props) => {
  return (
    <DefaultHelmet
      defaultTitle="Encl: Send files to anyone easily and securely"
      titleTemplate="Encl: %s"
    >
      {props.children}
    </DefaultHelmet>
  )
}

export default Helmet
