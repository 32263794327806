import { browserName } from './utils'

function checkStreams () {
  try {
    // eslint-disable-next-line no-new
    new ReadableStream({
      pull () {}
    })
    return true
  } catch (e) {
    return false
  }
}

function checkBlob () {
  try {
    // eslint-disable-next-line no-new
    new Blob([])
    return true
  } catch (e) {
    return false
  }
}

function checkCrypto () {
  return crypto ? crypto.subtle !== undefined : false
}

export function getSupported () {
  const nativeStreams = checkStreams()
  const nativeCrypto = checkCrypto()
  const nativeBlob = checkBlob()
  const result = nativeStreams && nativeCrypto && nativeBlob
  if (!result) {
    console.warn(`Compability status:
    Streams API: ${nativeStreams}
    Crypto API: ${nativeCrypto}
    Blob API: ${nativeBlob}`)
  }
  return result
}

export function getFeatures () {
  const browser = browserName()
  const serviceWorker = 'serviceWorker' in navigator && browser !== 'edge'
  const nativeStreams = checkStreams()

  const mobileFirefox =
    browser === 'firefox' && /mobile/i.test(navigator.userAgent)

  // Checks iOS version which can support createObjectURL API for saving file
  let createObjectURL = true
  // Reference: https://github.com/faisalman/ua-parser-js/blob/master/src/ua-parser.js#L753
  const iOSMatcher = /ip[honead]{2,4}(?:.*os\s([\w]+)\slike\smac|;\sopera)/i.exec(navigator.userAgent)
  if (iOSMatcher && iOSMatcher.length === 2) {
    // Match the second one which holds the version string
    const version = Number(iOSMatcher[1].replace('_', '.'))
    if (version < 13) {
      createObjectURL = false
    }
  }

  return {
    serviceWorker,
    createObjectURL,
    stream: nativeStreams,
    streamDownload:
      nativeStreams && serviceWorker && browser !== 'safari' && !mobileFirefox
  }
}

export function hasStorage (name) {
  try {
    const storage = window[name]
    storage.setItem('__test', '1')
    storage.removeItem('__test')
    return true
  } catch (e) {
    return false
  }
}
