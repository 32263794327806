import base64url from 'base64url'
import scrypt from 'scrypt-js'

import { encryptStream, decryptStream } from './ece'
import { signJWT } from './jwt'

const encoder = new TextEncoder()

export default class KeyScheduler {
  constructor (rawSecretB64, salt) {
    if (rawSecretB64) {
      this.rawSecret = base64url.toBuffer(rawSecretB64)
    } else {
      this.rawSecret = Buffer.from(crypto.getRandomValues(new Uint8Array(16)))
    }

    if (salt) {
      this.salt = Buffer.from(salt)
    } else {
      this.salt = Buffer.from(new Uint8Array())
    }

    this.rawSecretPromise = crypto.subtle.importKey(
      'raw',
      this.rawSecret.buffer,
      {
        name: 'HKDF',
        hash: 'SHA-256'
      },
      false,
      ['deriveKey']
    )
    this.metadataAuthKeyPromise = this.rawSecretPromise.then((rawSecret) => {
      return crypto.subtle.deriveKey(
        {
          name: 'HKDF',
          salt: this.salt.buffer,
          info: encoder.encode('enclMetadataAuthKey'),
          hash: 'SHA-256'
        },
        rawSecret,
        {
          name: 'HMAC',
          hash: 'SHA-256',
          length: 256
        },
        true,
        ['sign']
      )
    })
    this._updateContentMasterKey(this.rawSecretPromise)
    this.contentKey = null
  }

  get rawSecretB64 () {
    return base64url(this.rawSecret)
  }

  setPassword (password) {
    const N = 1 << 15
    const r = 8
    const p = 1
    const dkLen = 32
    const passwordBuf = Buffer.from(password.normalize('NFKC'))
    this.hasPassword = true
    const promise = this.rawSecretPromise
      .then((rawSecret) => {
        return crypto.subtle.deriveKey(
          {
            name: 'HKDF',
            salt: this.salt.buffer,
            info: encoder.encode('enclPasswordSalt'),
            hash: 'SHA-256'
          },
          rawSecret,
          {
            name: 'HMAC',
            hash: 'SHA-256',
            length: 256
          },
          true,
          ['sign']
        )
      })
      .then((key) => crypto.subtle.exportKey('raw', key))
      .then((salt) => scrypt.scrypt(passwordBuf, Buffer.from(salt), N, r, p, dkLen))
      .then((key) => crypto.subtle.importKey(
        'raw',
        key,
        {
          name: 'HKDF',
          hash: 'SHA-256'
        },
        false,
        ['deriveKey']
      ))
    this._updateContentMasterKey(promise)
  }

  async metadataAuthKey () {
    const authKey = await this.metadataAuthKeyPromise
    const rawAuth = await crypto.subtle.exportKey('raw', authKey)
    return Buffer.from(rawAuth)
  }

  async metadataAuthToken (sub, expiresIn) {
    const key = await this.metadataAuthKeyPromise
    return signJWT({ sub, iss: 'MAK', exp: Date.now() + expiresIn }, key)
  }

  async contentAuthKey () {
    const authKey = await this.contentAuthKeyPromise
    const rawAuth = await crypto.subtle.exportKey('raw', authKey)
    return Buffer.from(rawAuth)
  }

  async contentAuthToken (sub, expiresIn) {
    const key = await this.contentAuthKeyPromise
    return signJWT({ sub, iss: 'CAK', exp: Date.now() + expiresIn }, key)
  }

  async wrapKey (ikm) {
    const inputKey = await crypto.subtle.importKey(
      'raw',
      ikm.buffer,
      {
        name: 'HMAC',
        hash: 'SHA-256'
      },
      true,
      ['sign']
    )
    const kek = await this.keyWrappingKeyPromise
    const wrappedKey = await crypto.subtle.wrapKey(
      'raw',
      inputKey,
      kek,
      'AES-KW'
    )
    return Buffer.from(wrappedKey)
  }

  async unwrapKey (wrappedKey) {
    const kek = await this.keyWrappingKeyPromise
    const outputKey = await crypto.subtle.unwrapKey(
      'raw',
      wrappedKey,
      kek,
      'AES-KW',
      {
        name: 'HMAC',
        hash: 'SHA-256'
      },
      true,
      ['sign']
    )
    const unwrappedKey = await crypto.subtle.exportKey('raw', outputKey)
    return Buffer.from(unwrappedKey)
  }

  generateContentKey () {
    if (this.contentKey) {
      throw new Error('CEK exists already')
    }
    this.contentKey = Buffer.from(crypto.getRandomValues(new Uint8Array(16)))
  }

  async importContentKey (ecek) {
    if (this.contentKey) {
      throw new Error('CEK exists already')
    }
    this.contentKey = await this.unwrapKey(ecek)
  }

  async exportContentKey () {
    if (!this.contentKey) {
      throw new Error('CEK is not initialized')
    }
    const contentKey = await this.contentKey
    return this.wrapKey(contentKey)
  }

  encryptContentStream (stream) {
    if (!this.contentKey) {
      throw new Error('CEK is not initialized')
    }
    return encryptStream(stream, this.contentKey)
  }

  decryptContentStream (stream) {
    if (!this.contentKey) {
      throw new Error('CEK is not initialized')
    }
    return decryptStream(stream, this.contentKey)
  }

  _updateContentMasterKey (contentMasterKeyPromise) {
    this.contentAuthKeyPromise = contentMasterKeyPromise.then((rawSecret) => {
      return crypto.subtle.deriveKey(
        {
          name: 'HKDF',
          salt: this.salt.buffer,
          info: encoder.encode('enclContentAuthKey'),
          hash: 'SHA-256'
        },
        rawSecret,
        {
          name: 'HMAC',
          hash: 'SHA-256',
          length: 256
        },
        true,
        ['sign']
      )
    })
    this.keyWrappingKeyPromise = contentMasterKeyPromise.then((rawSecret) => {
      return crypto.subtle.deriveKey(
        {
          name: 'HKDF',
          salt: this.salt.buffer,
          info: encoder.encode('enclKeyWrappingKey'),
          hash: 'SHA-256'
        },
        rawSecret,
        {
          name: 'AES-KW',
          length: 256
        },
        false,
        ['wrapKey', 'unwrapKey']
      )
    })
  }
}
