import axios from 'axios'
import { getDeviceID } from './device'
import axiosRetry from 'axios-retry'

export default class Client {
  constructor (opts) {
    this.opts = {
      ...opts
    }
    if (this.opts.client) {
      this._httpClient = this.opts.client
    }
  }

  setHttpClient (client) {
    this._httpClient = client
  }

  setAccessTokenGenerator (accessTokenGenerator) {
    this.opts.accessTokenGenerator = accessTokenGenerator
  }

  async createBundle (req) {
    const resp = await this._http.post('/api/v1/bundles', req)
    return resp.data
  }

  async revokeBundle (key, ownerToken) {
    const resp = await this._http.delete(`/api/v1/bundles/${key}`, {
      headers: ownerTokenHeader(ownerToken)
    })
    return resp.data
  }

  async getBundleMetadata (key, token, ownerToken) {
    const resp = await this._http.get(`/api/v1/bundles/${key}`, {
      headers: {
        ...signatureHeader(token),
        ...ownerTokenHeader(ownerToken)
      }
    })
    return resp.data
  }

  async updateBundleMetadata (key, ownerToken, req) {
    const resp = await this._http.put(`/api/v1/bundles/${key}`, req, {
      headers: ownerTokenHeader(ownerToken)
    })
    return resp.data
  }

  async uploadRequest (key, ownerToken, req) {
    const resp = await this._http.post(`/api/v1/bundles/${key}/upload`, req, {
      headers: ownerTokenHeader(ownerToken)
    })
    return resp.data
  }

  async downloadRequest (key, file, token, ownerToken) {
    const resp = await this._http.get(`/api/v1/bundles/${key}/download`, {
      headers: {
        ...signatureHeader(token),
        ...ownerTokenHeader(ownerToken)
      },
      params: {
        file: file
      }
    })
    return resp.data
  }

  async authToken (state, code, codeVerifier) {
    const resp = await this._http.post('/api/v1/oauth/token', {
      grant_type: 'authorization_code',
      state,
      code,
      code_verifier: codeVerifier
    })
    return resp.data
  }

  async refreshToken (token) {
    const resp = await this._http.post('/api/v1/oauth/token', {
      grant_type: 'refresh_token',
      refresh_token: token
    })
    return resp.data
  }

  async userProfile () {
    const resp = await this._http.get('/api/v1/users/current')
    return resp.data
  }

  async updateUser (patch) {
    const resp = await this._http.patch('/api/v1/users/current', patch)
    return resp.data
  }

  async uploadUserImageRequest (imageType, meta) {
    const resp = await this._http.post(`/api/v1/users/current/images/${imageType}`, meta)
    return resp.data
  }

  async listBundle () {
    const resp = await this._http.get('/api/v1/bundles')
    return resp.data
  }

  oauthURL (state, codeChallenge) {
    return `${this.opts.baseURL}/api/v1/oauth?state=${state}&code_challenge=${codeChallenge}`
  }

  accessTokenHeader () {
    if (this.opts.accessTokenGenerator) {
      const accessToken = this.opts.accessTokenGenerator()
      if (accessToken) {
        return {
          Authorization: `Bearer ${accessToken}`
        }
      }
    }
    return {}
  }

  get _http () {
    if (this._httpClient) {
      return this._httpClient
    }
    const client = axios.create({
      baseURL: this.opts.baseURL,
      headers: {
        ...this.accessTokenHeader(),
        ...deviceIDHeader()
      }
    })
    axiosRetry(client, { retries: 3 })
    return client
  }
}

function signatureHeader (token) {
  if (token) {
    return {
      'X-Encl-Signature': token
    }
  }
  return {}
}

function ownerTokenHeader (token) {
  if (token) {
    return {
      'X-Encl-Owner-Token': token
    }
  }
  return {}
}

function deviceIDHeader () {
  return {
    'X-Encl-Device-Id': getDeviceID()
  }
}

async function _downloadStream (url, signal) {
  const response = await fetch(url, {
    signal: signal,
    method: 'GET'
  })

  if (response.status !== 200) {
    throw new Error(response.status)
  }
  return response.body
}

async function tryDownloadStream (url, signal, tries = 2) {
  try {
    const result = await _downloadStream(url, signal)
    return result
  } catch (e) {
    if (e.message === '401' && --tries > 0) {
      return tryDownloadStream(url, signal, tries)
    }
    if (e.name === 'AbortError') {
      throw new Error('0')
    }
    throw e
  }
}

export function downloadStream (url) {
  const controller = new AbortController()
  function cancel () {
    controller.abort()
  }
  return {
    cancel,
    result: tryDownloadStream(url, controller.signal)
  }
}
