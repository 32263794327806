import Client from '../client'
import Auth from '../auth'
import { createDefaultStore } from '../store'
import { distantFuture, getDB, isIDBMissing } from './db'

const initialState = {
  bundles: {},
  localActiveKeys: null,
  localExpiredKeys: null,
  userActiveKeys: null,
  userExpiredKeys: null,
  hasActiveLocalBundles: false
}

export default class Manager {
  constructor (opts) {
    const defaults = {
      store: createDefaultStore(),
      getAuth: () => new Auth(opts)
    }

    this.opts = {
      ...defaults,
      ...opts
    }

    this._store = this.opts.store(initialState)
    this._client = new Client({
      ...opts,
      accessTokenGenerator: () => this.opts.getAuth().accessToken()
    })
    this._db = getDB()
  }

  get state () {
    return this._store.getState()
  }

  setState (state) {
    return this._store.setState(state)
  }

  async updateHasActiveLocalBundles () {
    const { keys } = await this._fetchLocalBundles(false)
    const hasActiveLocalBundles = keys.length > 0
    this.setState({ hasActiveLocalBundles })
    return hasActiveLocalBundles
  }

  async fetchRemoteBundles () {
    await this.updateHasActiveLocalBundles()
    const { bundles: localActiveBundles } = await this._fetchLocalBundles(false)
    const { bundles: localExpireBundles } = await this._fetchLocalBundles(true)
    this.setState({
      bundles: { ...this.state.bundles, ...localExpireBundles, ...localActiveBundles }
    })
    const resp = await this._client.listBundle()
    const localBundles = this.state.bundles
    const bundles = {}
    resp.map((item) => {
      const localBundleMetadata = localBundles[item.key] ? localBundles[item.key] : {}
      bundles[item.key] = {
        ...localBundleMetadata,
        ...toBundleMetadata(item)
      }
    })
    const userActiveKeys = []
    const userExpiredKeys = []
    for (const key in bundles) {
      const item = bundles[key]
      if (item.expiresAt && item.expiresAt <= Date.now()) {
        userExpiredKeys.push(key)
      } else {
        userActiveKeys.push(key)
      }
    }
    this.setState({
      bundles,
      userActiveKeys,
      userExpiredKeys
    })
  }

  async fetchLocalActiveBundles () {
    const { keys, bundles } = await this._fetchLocalBundles(false)
    this.setState({
      bundles: { ...this.state.bundles, ...bundles },
      localActiveKeys: keys
    })
    await this.updateHasActiveLocalBundles()
    await this._refreshBundles(keys)
  }

  async fetchLocalExpiredBundles () {
    const { keys, bundles } = await this._fetchLocalBundles(true)
    this.setState({
      bundles: { ...this.state.bundles, ...bundles },
      localExpiredKeys: keys
    })
    await this.updateHasActiveLocalBundles()
    await this._refreshBundles(keys)
  }

  async getBundle (key) {
    try {
      let bundle = this.state.bundles[key]
      if (!bundle) {
        // try to fetch it from db
        bundle = await this._db.bundles.get(key)
        bundle = fromStoreFormat(bundle)
      }
      return bundle
    } catch (e) {
      if (isIDBMissing(e)) {
        return null
      }
      throw e
    }
  }

  async putBundle (metadata) {
    const item = await this._putBundle(metadata)
    await this.updateHasActiveLocalBundles()
    const bundles = {
      ...this.state.bundles,
      [item.key]: item
    }
    let keysListName = 'localActiveKeys'
    if (item.expiresAt && item.expiresAt <= Date.now()) {
      keysListName = 'localExpiredKeys'
    }
    let keysList = this.state[keysListName]
    if (keysList === null) {
      keysList = [item.key]
    } else if (!keysList.includes(item.key)) {
      keysList.unshift(item.key)
    }
    this.setState({ bundles, [keysListName]: keysList })
  }

  async revoke (key) {
    const bundle = await this.getBundle(key)
    const ownerToken = bundle ? bundle.ownerToken : undefined
    const result = await this._client.revokeBundle(key, ownerToken)
    if (ownerToken) {
      await this.putBundle(toBundleMetadata(result))
      await this.fetchLocalActiveBundles()
    } else {
      await this.fetchRemoteBundles()
    }
  }

  forget (key) {
    try {
      return this._db.bundles.delete(key)
    } catch (e) {
      if (!isIDBMissing(e)) {
        throw e
      }
    }
  }

  async _putBundle (metadata) {
    let item
    try {
      item = await this._db.bundles.get(metadata.key)
    } catch (e) {
      if (!isIDBMissing(e)) {
        throw e
      }
    }
    if (!item) {
      item = {}
    }
    Object.assign(item, metadata)
    try {
      await this._db.bundles.put(toStoreFormat(item))
    } catch (e) {
      if (!isIDBMissing(e)) {
        throw e
      }
    }
    return item
  }

  async _fetchLocalBundles (selectExpired) {
    const now = Date.now()
    let rs
    try {
      let q = this._db.bundles.where('expiresAt')
      if (selectExpired) {
        q = q.belowOrEqual(now)
      } else {
        q = q.above(now)
      }
      rs = await q
        .reverse()
        .sortBy('publishedAt')
    } catch (e) {
      if (isIDBMissing(e)) {
        rs = []
      } else {
        throw e
      }
    }
    const keys = []
    const bundles = {}
    rs.forEach((bundle) => {
      bundles[bundle.key] = fromStoreFormat(bundle)
      // filters out if belongs to user bundles
      if (bundle.ownerToken) {
        keys.push(bundle.key)
      }
    })
    return {
      keys, bundles
    }
  }

  async _refreshBundles (keys) {
    const promises = keys.map((key) => {
      const ownerToken = this.state.bundles[key].ownerToken
      return this._client.getBundleMetadata(key, null, ownerToken)
    })
    const results = await Promise.all(promises)
    const bundles = {}
    for (const result of results) {
      const item = await this._putBundle(toBundleMetadata(result))
      bundles[item.key] = item
    }
    this.setState({
      bundles: { ...this.state.bundles, ...bundles }
    })
  }
}

export function toBundleMetadata (response) {
  const md = {
    key: response.key,
    title: response.title,
    size: response.size,
    expiresAt: response.expires_at ? new Date(response.expires_at).getTime() : null,
    publishedAt: response.published_at ? new Date(response.published_at).getTime() : null,
    openCount: response.open_count,
    openLimit: response.open_limit
  }
  if (response.owner_token) {
    md.ownerToken = response.owner_token
  }
  if (response.url) {
    md.url = response.url
  }
  if (response.is_open_limit_reached) {
    md.isOpenLimitReached = response.is_open_limit_reached
  }
  return md
}

/**
 * Convert bundle to store format. Because idb key cannot be null, this function normalize null
 * key values to comparable values.
 *
 * @param {object} bundle
 */
function toStoreFormat (bundle) {
  if (!bundle) {
    return bundle
  }
  return {
    ...bundle,
    expiresAt: !bundle.expiresAt ? distantFuture : bundle.expiresAt
  }
}

/**
 * Convert bundle from store format.
 *
 * @param {object} bundle
 */
function fromStoreFormat (bundle) {
  if (!bundle) {
    return bundle
  }
  return {
    ...bundle,
    expiresAt: bundle.expiresAt === distantFuture ? null : bundle.expiresAt
  }
}
