import Col from 'react-bootstrap/Col'

const PaneFrame = (props) => {
  const hideBoxShadowInMobile = props.hideBoxShadowInMobile ? 'panel-box-shadow-none panel-box-shadow-md' : 'panel-box-shadow'
  const hideMobilePaddingTop = props.hideMobilePaddingTop ? 'pt-0' : ''
  const panelClassName = props.panelClassName
  return (
    <Col className={`my-md-3 panel-container flex-fill ${props.className}`}>
      <div className={`panel ${panelClassName} ${hideMobilePaddingTop} ${hideBoxShadowInMobile}`}>
        {props.children}
      </div>
    </Col>
  )
}

export default PaneFrame
