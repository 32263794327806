import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'

import uploaderReducer from './uploader'
import viewerReducer from './viewer'
import managerReducer from './manager'
import authReducer from './auth'
import userReducer from './user'

const logMiddleware = createLogger({
  predicate: () => process.env.NODE_ENV !== 'production'
})

export default configureStore({
  reducer: {
    uploader: uploaderReducer,
    viewer: viewerReducer,
    manager: managerReducer,
    auth: authReducer,
    user: userReducer
  },
  middleware: [...getDefaultMiddleware(), logMiddleware]
})