import classNames from 'classnames'
import { useRoute, Link } from 'wouter'

const ActiveLink = props => {
  const [isActive] = useRoute(props.href)
  const isActiveClass = isActive ? 'active' : ''
  const className = classNames(isActiveClass, props.className)
  return (
    <Link {...props}>
      <a className={className}>{props.children}</a>
    </Link>
  )
}

export default ActiveLink
