import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlarmExclamation, faExclamationCircle, faFileExclamation } from '@fortawesome/pro-regular-svg-icons'

import TryEnclButton from '@/components/try-encl-button'
import Helmet from '@/components/helmet'
import { types } from '@/core'

// TODO: move this to a strings file
const ERROR_TITLES = {
  [types.ERROR_NOT_FOUND]: "Not Found",
  [types.ERROR_EXPIRED]: "Expired",
  [types.ERROR_UNKNOWN]: "Oops!",
  [types.ERROR_INCOMPATIBLE]: "Unsupported Browser"
}
const ERROR_MESSAGES = {
  [types.ERROR_NOT_FOUND]: "We couldn't find what you are looking for.",
  [types.ERROR_EXPIRED]: "Your link has expired.",
  [types.ERROR_UNKNOWN]: "Something went wrong. Please try again.",
  [types.ERROR_INCOMPATIBLE]: "We're sorry, your browser is not supported currently."
}

const ERROR_ICONS = {
  [types.ERROR_NOT_FOUND]: faFileExclamation,
  [types.ERROR_EXPIRED]: faAlarmExclamation,
  [types.ERROR_UNKNOWN]: faExclamationCircle,
  [types.ERROR_INCOMPATIBLE]: faExclamationCircle
}

const ErrorPane = (props) => {
  if (!props.error) {
    props.error = types.ERROR_UNKNOWN
  }
  const title = ERROR_TITLES[props.error]
  const message = ERROR_MESSAGES[props.error]
  const icon = ERROR_ICONS[props.error]
  return (
    <>
      <Helmet>
        <title />
      </Helmet>
      <div className="d-flex flex-column h-100">
        <Row className="panel-row flex-fill align-items-center">
          <Col className="text-center">
            <FontAwesomeIcon icon={icon} size="3x" className="text-darkblue" />
            <h2 className="mt-4 font-weight-bold text-darkblue">{title}</h2>
            <p>{message}</p>
          </Col>
        </Row>
        <Row className="panel-row border-top py-3">
          <Col>
            <TryEnclButton />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default ErrorPane
