import { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import ProfileIcon from '@/components/profile-icon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'

import { userSelector, fetchUserProfile, changeBackground, changeLogo, restoreBackgroundLogoToDefault } from '@/stores/user'
import { logEvent } from '@/utils'

import style from './style'
import styleVariables from '@/style'

const LOADING_OPACITY = 0.3

const EditButton = (props) => {
  const ref = useRef()
  const onChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      props.onChange({file: e.target.files[0]})
    }
  }

  return (
    <div className={props.className} style={props.style}>
      <Button size="sm" variant="light" className="font-weight-bold" onClick={() => ref.current.click()}>{props.label}</Button>
      <input accept=".png,.jpg,.jpeg" ref={ref} type="file" hidden="true" onChange={onChange} />
    </div>
  )
}

const ChangeBackgroundPane = () => {
  const state = useSelector(userSelector)
  const dispatch = useDispatch()
  const defaultBackground = state.userProfile.background === 'default'
  const defaultLogo = state.userProfile.logo === 'default'

  useEffect(() => {
    if (state.changeBackgroundShown) {
      dispatch(fetchUserProfile())
    }
  }, [dispatch, state.changeBackgroundShown])

  useEffect(() => {
    if (!state.userProfile.backgroundPending && !state.userProfile.logoPending) {
      dispatch(fetchUserProfile())
    }
  }, [dispatch, state.userProfile.backgroundPending, state.userProfile.logoPending])

  const onBackgroundChange = ({file}) => {
    dispatch(changeBackground({ file }))
    logEvent('Encl_backgroundChange')
  }

  const onLogoChange = ({file}) => {
    dispatch(changeLogo({ file }))
    logEvent('Encl_logoChange')
  }

  const restoreToDefault = () => {
    dispatch(restoreBackgroundLogoToDefault())
    logEvent('Encl_restoreBackgroundLogo')
  }

  // TODO: Allow customized colour
  const backgroundSrc = !defaultBackground ? state.userProfile.background : ''
  const backgroundColour = styleVariables.defaultBackgroundColour

  const backgroundImageOpacity = state.userProfile.backgroundPending ? LOADING_OPACITY : 1
  const iconOpacity = state.userProfile.logoPending ? LOADING_OPACITY : 1

  const backgroundStyle = {
    backgroundColor: backgroundColour
  }
  const backgroundImageStyle = !defaultBackground ? {
    backgroundImage: `url(${backgroundSrc})`,
    opacity: backgroundImageOpacity
  } : ''

  return (
    <div>
      {/* TODO: Set default value and allow different colour value */}
      <div style={backgroundStyle}>
        <div className={style['preview-img-pane']}>
          <div className="d-flex justify-content-center" style="min-height: inherit;">
            <div className={style['preview-img']} style={backgroundImageStyle} />
            {!defaultBackground && state.userProfile.backgroundPending &&
             <div className="position-absolute align-self-center" style={{ opacity: state.userProfile.backgroundPending ? 1 : 0 }}>
               <FontAwesomeIcon icon={faSpinnerThird} size="lg" className="fa-spin" />
             </div>
            }
          </div>
        </div>
        <EditButton className={`${style['background-edit-btn']} mt-4 mr-4`} label="Edit" onChange={onBackgroundChange} />
      </div>
      <Row className="justify-content-center">
        <Col xs="12" className="pt-2">
          <ProfileIcon withShadow className="p-4" icon={state.userProfile.logo} style={{ opacity: iconOpacity }}>
            {!defaultLogo && state.userProfile.logoPending &&
             <div className={style['logo-loading']} style={{ opacity: state.userProfile.logoPending ? 1 : 0 }}>
               <FontAwesomeIcon icon={faSpinnerThird} size="lg" className="fa-spin" />
             </div>
            }
          </ProfileIcon>
        </Col>
        <Col className="mt-2">
          <Row>
            <Col>
              <div className="text-danger">
                {state.userProfile.error && 'File is too large'}
              </div>
            </Col>
            <Col xs="auto">
              <EditButton label="Edit" onChange={onLogoChange} />
            </Col>
            <Col className="text-right">
              <Button size="sm" variant="light" className="font-weight-bold" onClick={restoreToDefault}>Restore to default</Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="small text-darkgray">
        Max image size 1MB.
      </div>
    </div>
  )
}

export default ChangeBackgroundPane
