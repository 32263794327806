import { isNetworkError } from 'axios-retry'
import PCancelable from 'p-cancelable'

import { getFileNameAndExtension, getFileType, isQuotaExceeded, timeUsed, readFileEntry, readDirectoryEntry } from './utils'
import { blobStream } from './streams'
import Client from './client'
import { createDefaultStore } from './store'
import KeyScheduler from './keyScheduler'
import Manager, { toBundleMetadata } from './manager'
import Auth from './auth'
import BlobUploader from './blob'
import * as types from './types'
import { isFileBlocked } from './filesafe'

const FILE_SIZE_LIMIT = 1048576 * 2048 // 2048 MiB
const SECONDS_IN_DAY = 86400

const initialState = {
  key: null,
  rawSecret: null,
  files: {},
  fileIDs: [],
  currentProgress: 0,
  totalProgress: 0,
  timeUsed: 0,
  initialTime: -1,
  title: '',
  isDefaultTitle: true,
  isFinalized: false,
  isCompleted: false,
  expiresIn: 7 * SECONDS_IN_DAY,
  openLimit: 0,
  password: '',
  showSender: true,
  notifyFirstOpen: false,
  ownerToken: '',
  url: '',
  notification: false,
  logs: false,
  error: null
}

export default class Uploader {
  constructor (opts) {
    const defaults = {
      store: createDefaultStore(),
      getAuth: () => new Auth(opts),
      getManager: () => new Manager(opts),
      defaultTitle: 'Untitled'
    }

    this.opts = {
      ...defaults,
      ...opts
    }

    this._store = this.opts.store(initialState)
    this._client = new Client({
      ...opts,
      accessTokenGenerator: () => this.opts.getAuth().accessToken()
    })
    this._lastFileID = 0
    this._fileStreams = {}
    this._keyScheduler = null
    this._uploadTasks = []
    this._createBundlePromise = null
    this._defaultTitle = this.opts.defaultTitle
  }

  get state () {
    return this._store.getState()
  }

  setState (state) {
    return this._store.setState(state)
  }

  get title () {
    return this.state.title
  }

  setTitle (title) {
    if (title === this.title) {
      return
    }
    if (!title) {
      return this._updateDefaultTitle(true)
    }
    return this.setState({ title, isDefaultTitle: false })
  }

  get expiresIn () {
    return this.state.expiresIn
  }

  setExpiresIn (expiresIn) {
    if (!Number.isInteger(expiresIn)) {
      throw new Error('expiresIn must be an integer')
    }
    this.setState({ expiresIn: expiresIn })
  }

  get openLimit () {
    return this.state.openLimit
  }

  setOpenLimit (openLimit) {
    if (!Number.isInteger(openLimit)) {
      throw new Error('expiresIn must be an integer')
    }
    this.setState({ openLimit })
  }

  get password () {
    return this.state.password
  }

  setPassword (password) {
    this.setState({ password })
  }

  get showSender () {
    return this.state.showSender
  }

  setShowSender (showSender) {
    this.setState({ showSender })
  }

  get notifyFirstOpen () {
    return this.state.notifyFirstOpen
  }

  setNotifyFirstOpen (notifyFirstOpen) {
    this.setState({ notifyFirstOpen })
  }

  setName (name) {
    this.setState({ name })
  }

  get files () {
    return this.state.files
  }

  getFile (fileID) {
    return this.state.files[fileID]
  }

  _updateDefaultTitle (forced) {
    let { isDefaultTitle } = this.state
    if (forced) {
      isDefaultTitle = true
    }
    if (isDefaultTitle) {
      const title = typeof this._defaultTitle === 'function'
        ? this._defaultTitle(this.state) : this._defaultTitle
      return this.setState({ title, isDefaultTitle })
    }
  }

  /**
   * Add a new file to upload.
   *
   * @param {object} file object to add
   * @returns {}
   */
  async addFile (file, newName) {
    const id = this._nextFileID()
    const name = newName || file.name
    const { extension } = getFileNameAndExtension(name)
    const fileType = getFileType(file)
    const size = file.size

    if (size > FILE_SIZE_LIMIT) {
      this.setState({
        error: types.ERROR_FILE_TOO_LARGE
      })
      throw new Error(types.ERROR_FILE_TOO_LARGE)
    }

    const fileState = {
      id,
      name,
      extension,
      type: fileType,
      size: size,
      progress: 0,
      bytesUploaded: 0,
      uploadCompleted: false,
      uploadStarted: null,
      digest: null
    }
    const [fileStream, testStream] = blobStream(file).tee()
    // TODO: check user features to block or not
    const isBlocked = await isFileBlocked(testStream, extension)
    if (isBlocked) {
      this.setState({
        error: types.ERROR_UPLOAD_FILE_NOT_ALLOWED
      })
      throw new Error(types.ERROR_UPLOAD_FILE_NOT_ALLOWED)
    }
    this._fileStreams[fileState.id] = fileStream

    const { files, fileIDs } = this.state
    const newFileIDs = fileIDs.concat(fileState.id)
    const newFiles = { ...files, ...{ [fileState.id]: fileState } }
    this.setState({ files: newFiles, fileIDs: newFileIDs })
    this._updateDefaultTitle()

    return fileState.id
  }

  removeFile (fileID) {
    const files = { ...this.state.files }
    const { fileIDs } = this.state
    let newfileIDs = fileIDs
    if (files[fileID]) {
      delete files[fileID]
      newfileIDs = fileIDs.filter((e) => (e !== parseInt(fileID)))
    }
    this.setState({ files, fileIDs: newfileIDs })
    this._updateDefaultTitle()
  }

  /**
   * Add files from a new instance of FileSystemFileEntry or FileSystemDirectoryEntry.
   *
   * @param {FileSystemFileEntry|FileSystemDirectoryEntry} entry entry to add
   * @returns {}
   */
  async addFileSystemEntry (entry) {
    if (entry.isDirectory) {
      const files = await readDirectoryEntry(entry)
      for (const file of files.flat(Infinity)) {
        if (!/^\./.test(file.name)) {
          // Start without first "/"
          await this.addFile(file, file.fullPath.substring(1))
        }
      }
    } else if (entry.isFile) {
      const file = await readFileEntry(entry)
      await this.addFile(file)
    } else {
      throw new Error('neither is directory nor file')
    }
  }

  totalSize () {
    let totalSize = 0
    Object.keys(this.state.files).forEach(fileID => {
      const file = this.getFile(fileID)
      totalSize += file.size
    })
    return totalSize
  }

  async finalize () {
    if (this.state.isFinalized) {
      throw new Error('Upload already finalized')
    }
    this.setState({
      isFinalized: true,
      currentProgress: 0,
      totalProgress: this.totalSize()
    })
    try {
      await this.resumeUpload()
      const indexKey = await this._uploadIndex()
      let mode = types.MODE_RAW_SECRET
      if (this.password) {
        this._keyScheduler.setPassword(this.password)
        mode = types.MODE_PASSWORD
      }
      const ecek = await this._keyScheduler.exportContentKey()
      const contentAuthKey = (await this._keyScheduler.contentAuthKey()).toString('hex')
      const metadataAuthKey = (await this._keyScheduler.metadataAuthKey()).toString('hex')
      const md = await this._client.updateBundleMetadata(this.state.key, this.state.ownerToken, {
        title: this.state.title,
        show_sender: this.state.showSender,
        size: this.totalSize(),
        expires_in: this.expiresIn,
        open_limit: this.openLimit,
        ecek: ecek.toString('base64'),
        index_key: indexKey,
        content_auth_key: contentAuthKey,
        metadata_auth_key: metadataAuthKey,
        mode: mode,
        email_notify_first_open: this.notifyFirstOpen
      })
      const url = `/t/${this.state.key}#${this.state.rawSecret}`
      md.url = url
      if (!this.opts.getAuth().isAuthenticated()) {
        md.owner_token = this.state.ownerToken
      }
      this.setState({
        url: url,
        isCompleted: true
      })
      await this.opts.getManager().putBundle(toBundleMetadata(md))
    } catch (e) {
      if (e.isCanceled) {
        this.setState({
          isFinalized: false,
          currentProgress: 0,
          totalProgress: 0
        })
      } else if (isQuotaExceeded(e)) {
        this.setState({
          error: types.ERROR_QUOTA_EXCEEDED
        })
      } else if (isNetworkError(e)) {
        this.setState({
          error: types.ERROR_NETWORK_CONNECTION
        })
        throw e
      } else {
        console.error(e)
        this.setState({
          error: types.ERROR_UNKNOWN
        })
        throw e
      }
    }
  }

  setFileState (fileID, state) {
    const file = this.getFile(fileID)
    if (!file) {
      throw new Error(`Can't set state for ${fileID}`)
    }
    this.setState({
      files: Object.assign({}, this.state.files, {
        [fileID]: Object.assign({}, file, state)
      })
    })
  }

  resumeUpload () {
    this.setState({
      initialTime: (new Date()).getTime()
    })
    this.state.fileIDs.forEach(fileID => {
      const file = this.getFile(fileID)
      if (file && !file.uploadStarted) {
        this._uploadTasks.push(this._uploadFile(fileID))
      }
    })
    return Promise.all(this._uploadTasks)
  }

  cancelAll () {
    this._uploadTasks.forEach(task => task.cancel())
    this._uploadTasks = []
    this._createBundlePromise = null
    this._keyScheduler = null
    this.setState({
      isFinalized: false,
      currentProgress: 0,
      totalProgress: 0,
      error: null
    })
  }

  async reset () {
    this.cancelAll()
    this._lastFileID = 0
    this._fileStreams = {}
    this._keyScheduler = null
    this._uploadTasks = []
    this._createBundlePromise = null
    this.setState(initialState)
  }

  resetError () {
    this.setState({
      error: null
    })
  }

  _nextFileID () {
    this._lastFileID++
    return this._lastFileID
  }

  _uploadFile (fileID) {
    this.setFileState(fileID, {
      uploadStarted: Date.now(),
      uploadCompleted: false,
      percentage: 0,
      bytesUploaded: 0
    })
    return new PCancelable((resolve, reject, onCancel) => {
      const file = this.getFile(fileID)
      const rejectHandler = (reason) => {
        this.setFileState(fileID, {
          uploadStarted: null
        })
        return reject(reason)
      }

      const uploadStream = async () => {
        await this._beforeUpload()
        const fileStreams = this._fileStreams[fileID].tee()
        this._fileStreams[fileID] = fileStreams[0]
        const streamPromise = this._uploadStream(file.size, fileStreams[1])
        onCancel(() => {
          streamPromise.cancel()
          this.setFileState(fileID, {
            uploadStarted: null
          })
        })
        const res = await streamPromise
        this.setFileState(fileID, {
          uploadCompleted: true,
          percentage: 1,
          bytesUploaded: file.size,
          digest: res.digest,
          key: res.key
        })
      }
      uploadStream().then(resolve, rejectHandler)
    })
  }

  async _uploadIndex () {
    const files = this.state.fileIDs.map(fileID => {
      const file = this.getFile(fileID)
      return {
        name: file.name,
        type: file.type,
        size: file.size,
        digest: file.digest,
        key: file.key
      }
    })
    const index = {
      files
    }
    const blob = new Blob([JSON.stringify(index)], { type: 'application/json' })
    const res = await this._uploadStream(blob.size, blobStream(blob))
    return res.key
  }

  _beforeUpload () {
    if (!this._createBundlePromise) {
      this._createBundlePromise = this._createBundle()
    }
    return this._createBundlePromise
  }

  async _createBundle () {
    const bundle = await this._client.createBundle({ size: this.totalSize() })
    this._keyScheduler = new KeyScheduler()
    this._keyScheduler.generateContentKey()
    this.setState({
      key: bundle.key,
      ownerToken: bundle.owner_token,
      rawSecret: this._keyScheduler.rawSecretB64
    })
  }

  // upload and return information of uploaded file
  _uploadStream (filesize, stream) {
    let prevUploaded = 0
    const onProgress = ({ uploaded }) => {
      const delta = uploaded - prevUploaded
      prevUploaded = uploaded
      this.setState({
        currentProgress: this.state.currentProgress + delta,
        timeUsed: timeUsed(this.state.initialTime)
      })
    }
    const blobUploader = new BlobUploader({
      requestUploadHandler: payload => this._client.uploadRequest(this.state.key, this.state.ownerToken, payload)
    })
    const encryptedStream = this._keyScheduler.encryptContentStream(stream)
    return blobUploader.uploadStream(filesize, 'application/octet-stream', encryptedStream, onProgress)
  }
}
