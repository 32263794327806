const blockedExtension = ['exe', 'dll', 'so', 'apk', 'bat']

const elfHeader = [0x7F, 0x45, 0x4C, 0x46]

const peHeader = [0x4D, 0x5A]

const blockedHeader = [elfHeader, peHeader]

export async function isFileBlocked (fileStream, extension = '') {
  const _isBlockedFile = await isBlockedFile(fileStream)
  const _isBlockedExtension = isBlockedExtension(extension)
  return _isBlockedFile || _isBlockedExtension
}

async function isBlockedFile (stream) {
  // read first block data
  const reader = stream.getReader()
  const data = await reader.read()
  const dataMagic = Buffer.from(data.value.subarray(0, 16))
  for (const header of blockedHeader) {
    const length = header.length
    const headerBuf = Buffer.from(header)
    const check = Buffer.from(dataMagic.subarray(0, length))
    if (check.equals(headerBuf)) {
      return true
    }
  }
  return false
}

export function isBlockedExtension (extension) {
  return blockedExtension.includes(extension)
}
