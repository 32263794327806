import axios from 'axios'
import { Buffer } from 'buffer'
import { isNetworkError } from 'axios-retry'
import sanitize from 'sanitize-filename'

import { getFileNameAndExtension, streamToArrayBuffer, delay, isThrowableError } from './utils'
import { blobStream, concatStream } from './streams'
import Client from './client'
import { createDefaultStore } from './store'
import KeyScheduler from './keyScheduler'
import * as types from './types'
import Zip from './zip'
import { getFeatures } from './features'
import Manager from './manager'
import Auth from './auth'
import { isBlockedExtension } from './filesafe'

const initialState = {
  key: null,
  rawSecret: null,
  files: {},
  fileIDs: [],
  totalProgress: 0,
  title: null,
  sender: null,
  expiresAt: null,
  confirmBurnAfterRead: false,
  password: '',
  error: null
}

const AUTH_TOKEN_EXPIRES_IN = 600

const MAXIMUM_HANG_SECOND = 20

export default class Viewer {
  constructor (key, rawSecret, opts) {
    const defaults = {
      store: createDefaultStore(),
      getAuth: () => new Auth(opts),
      getManager: () => new Manager(opts)
    }

    this.opts = {
      ...defaults,
      ...opts
    }

    const state = {
      ...initialState,
      key: key,
      rawSecret: rawSecret
    }
    this._store = this.opts.store(state)
    this._client = new Client({
      ...opts,
      accessTokenGenerator: () => this.opts.getAuth().accessToken()
    })
    this._metadataPromise = null
    this._keyScheduler = new KeyScheduler(rawSecret)
    this.fetchMetadata().catch(e => {
      if (isThrowableError(this.state.error)) {
        throw e
      }
    })
    this.features = getFeatures()
  }

  get state () {
    return this._store.getState()
  }

  setState (state) {
    return this._store.setState(state)
  }

  get files () {
    return this.state.files
  }

  get fileIDs () {
    return this.state.fileIDs
  }

  get title () {
    return this.state.title
  }

  get key () {
    return this.state.key
  }

  setFileState (fileID, state) {
    const files = Object.assign({}, this.files)
    const file = Object.assign({}, files[fileID], state)
    files[fileID] = file
    return this._store.setState({ files })
  }

  fetchMetadata () {
    if (!this._metadataPromise) {
      this._metadataPromise = this._fetchMetadata()
    }
    return this._metadataPromise
  }

  setPassword (password) {
    this.setState({ password, error: null })
    this._metadataPromise = this._fetchMetadata()
  }

  setConfirmBurnAfterRead () {
    this.setState({ confirmBurnAfterRead: true, error: null })
    this._metadataPromise = this._fetchMetadata()
  }

  setPasswordConfirmBurnAfterRead (password) {
    this.setState({ password, confirmBurnAfterRead: true, error: null })
    this._metadataPromise = this._fetchMetadata()
  }

  previewFile (fileID) {
    this.setState({ preview: this.files[fileID] })
  }

  clearPreviewFile () {
    // Clear the cache path in corresponding file
    // This is due to file cache is actually removed after complete download using service worker flow
    // Empty file causing the link is invalid, hence the cache path should be cleared.
    if (this.state.preview) {
      this.setFileState(this.state.preview.id, { cache: undefined })
    }
    this.setState({ preview: undefined })
  }

  async downloadFile (fileID, saveFile = true) {
    await this.fetchMetadata()
    console.log(`downloading file ${fileID}`)
    const file = this.files[fileID]
    if (!file) {
      throw new Error(`undefined fileID ${fileID}`)
    }
    if (isBlockedExtension(file.extension)) {
      this.setState({ error: types.ERROR_VIEW_FILE_NOT_ALLOWED })
      throw new Error(types.ERROR_VIEW_FILE_NOT_ALLOWED)
    }
    if (this.features.streamDownload && navigator.serviceWorker.controller) {
      await this._downloadSw(file.key, fileID, saveFile)
    } else {
      try {
        // For Chrome and Firefox in iOS user agent contains ChiOS/FxiOS, which is not the case for Safari
        if (/iOS/i.test(navigator.userAgent)) {
          this.setState({
            error: types.ERROR_DOWNLOAD_NOT_AVAILABLE
          })
          throw new Error('browser is not supported for download from client-side')
        }
        // fallback to blob download
        if (saveFile && file.cache) {
          saveFileWithURL(file.cache, file.name)
          return
        }
        const stream = await this._downloadStream(file.key, fileID)
        await this._saveFileStream(stream, file.name, file.type, file.id, saveFile)
      } catch (e) {
        this.handleDownloadError(e)
      }
    }
  }

  async downloadZip () {
    await this.fetchMetadata()
    const files = this.fileIDs.map((fileID) => {
      const extension = this.files[fileID].extension
      if (isBlockedExtension(extension)) {
        this.setState({ error: types.ERROR_VIEW_FILE_NOT_ALLOWED })
        throw new Error(types.ERROR_VIEW_FILE_NOT_ALLOWED)
      }
      return this.files[fileID]
    })
    const filename = `${sanitize(this.title)}.zip`
    if (this.features.streamDownload && navigator.serviceWorker.controller) {
      await this._downloadZipSw(filename, files)
    } else {
      try {
        console.log(`downloading ${files.length} files as zip`)
        const streams = await Promise.all(files.map((file) => this._downloadStream(file.key, file.id)))
        const stream = concatStream(streams)
        const zip = new Zip(files, stream)
        this._saveFileStream(zip.stream, filename, 'application/zip', null, true)
      } catch (e) {
        this.handleDownloadError(e)
      }
    }
  }

  flushCache () {
    for (const id in this.state.files) {
      const file = this.state.files[id]
      URL.revokeObjectURL(file.cache)
    }
  }

  reset () {
    this.flushCache()
    this.setState(initialState)
    this._metadataPromise = null
    this._keyScheduler = null
  }

  cancelDownload () {
    // cancelDownload do not actually cancel the download request now, causing multiple files download
    // progress might be inconsistent when only some of them errored.
    // Refer to: https://gitlab.com/blocksq/encl/-/issues/63
    this.setState({
      error: null
    })
    for (const id in this.state.files) {
      this.setFileState(id, {
        bytesDownloaded: 0
      })
    }
  }

  async _saveFileStream (stream, name, type, fileID, saveFile) {
    const data = await streamToArrayBuffer(stream)
    return new Promise((resolve, reject) => {
      const blob = new Blob([data], { type: type })
      if (!this.features.createObjectURL) {
        // This method is much slower but createObjectURL
        // is buggy on iOS 12
        const reader = new FileReader()
        reader.addEventListener('loadend', function () {
          if (reader.error) {
            return reject(reader.error)
          }
          if (reader.result) {
            if (saveFile) saveFileWithURL(reader.result, name)
          }
          resolve()
        })
        reader.readAsDataURL(blob)
      }
      const downloadUrl = URL.createObjectURL(blob)
      if (saveFile) saveFileWithURL(downloadUrl, name)
      if (fileID) {
        this.setFileState(fileID, { cache: downloadUrl })
      }
      // TODO: Decide when to revoke download for GC
      // URL.revokeObjectURL(downloadUrl)
      setTimeout(resolve, 100)
    })
  }

  async _fetchMetadata () {
    let metadata
    let ownerToken
    try {
      const localMetadata = await this.opts.getManager().getBundle(this.key)
      ownerToken = localMetadata ? localMetadata.ownerToken : null
      const authToken = await this._keyScheduler.metadataAuthToken(this.key, AUTH_TOKEN_EXPIRES_IN)
      metadata = await this._client.getBundleMetadata(this.key, authToken, ownerToken)
      this.setState({
        isOwner: ownerToken !== null
      })
    } catch (e) {
      console.error(e)
      let error = types.ERROR_UNKNOWN
      if (e.response) {
        if (e.response.status === 404) {
          error = types.ERROR_NOT_FOUND
        } else if (e.response.status === 410) {
          error = types.ERROR_EXPIRED
        }
      }
      this.setState({ error })
      throw new Error('error fetching metadata')
    }
    this.setState({
      title: metadata.title,
      sender: metadata.sender,
      expiresAt: metadata.expires_at,
      background: metadata.background,
      logo: metadata.logo
    })
    if (metadata.expires_at && new Date(metadata.expires_at).getTime() <= Date.now()) {
      this.setState({ error: types.ERROR_EXPIRED })
      throw new Error('bundle expired')
    }
    if (metadata.mode === types.MODE_PASSWORD) {
      if (!this.state.password) {
        if (metadata.open_limit === types.BURN_AFTER_READ) {
          this.setState({ error: types.ERROR_PASSWORD_REQUIRED_BURN_AFTER_READ })
          throw new Error('password and confirm required')
        }
        this.setState({ error: types.ERROR_PASSWORD_REQUIRED })
        throw new Error('password required')
      }
      this._keyScheduler.setPassword(this.state.password)
    }
    if (metadata.open_limit === types.BURN_AFTER_READ) {
      // Check if owner
      if (!this.state.isOwner && !this.state.confirmBurnAfterRead) {
        this.setState({ error: types.ERROR_BURN_AFTER_READ_WARNING })
        throw new Error('confirm required')
      }
    }
    let indexStream
    try {
      indexStream = await this._downloadStream(metadata.index_key, false, ownerToken)
    } catch (e) {
      if (e instanceof DOMException) {
        if (e.name === 'OperationError' && metadata.mode === types.MODE_PASSWORD) {
          if (metadata.open_limit === types.BURN_AFTER_READ) {
            this.setState({ error: types.ERROR_PASSWORD_INCORRECT_BURN_AFTER_READ })
            throw new Error('confirmed but password incorrect')
          }
          this.setState({ error: types.ERROR_PASSWORD_INCORRECT })
          throw new Error('password incorrect')
        }
      }
      if (e.response) {
        if (e.response.status === 410) {
          this.setState({ error: types.ERROR_EXPIRED })
          throw new Error('bundle expired')
        } else if (e.response.status === 401 && metadata.mode === types.MODE_PASSWORD) {
          if (metadata.open_limit === types.BURN_AFTER_READ) {
            this.setState({ error: types.ERROR_PASSWORD_INCORRECT_BURN_AFTER_READ })
            throw new Error('confirmed but password incorrect')
          }
          this.setState({ error: types.ERROR_PASSWORD_INCORRECT })
          throw new Error('password incorrect')
        }
      }
      if (isNetworkError(e)) {
        this.setState({ error: types.ERROR_NETWORK_CONNECTION })
        throw new Error('network error')
      }
      this.setState({ error: types.ERROR_UNKNOWN })
      console.error(e)
      throw new Error('unknown error')
    }
    const indexJSON = Buffer.from(await streamToArrayBuffer(indexStream)).toString('utf-8')
    const index = JSON.parse(indexJSON)
    const fileIDs = []
    const files = {}
    index.files.forEach((indexItem, i) => {
      const id = i + 1
      const key = indexItem.key
      const name = indexItem.name
      const extension = getFileNameAndExtension(name).extension
      const fileType = indexItem.type
      const size = indexItem.size
      const digest = indexItem.digest
      const fileState = {
        id,
        key,
        name,
        extension,
        type: fileType,
        size,
        digest,
        progress: 0,
        bytesDownloaded: 0,
        downloadCompleted: false,
        downloadStarted: null
      }
      files[id] = fileState
      fileIDs.push(id)
    })

    this.setState({
      files,
      fileIDs
    })
  }

  async _fetchDownloadUrl (fileKey, ownerToken) {
    const token = await this._keyScheduler.contentAuthToken(this.key, AUTH_TOKEN_EXPIRES_IN)
    const req = await this._client.downloadRequest(this.key, fileKey, token, ownerToken)
    if (!this._keyScheduler.contentKey) {
      await this._keyScheduler.importContentKey(Buffer.from(req.ecek, 'base64'))
    }
    return req.url
  }

  handleDownloadError (e) {
    console.error(e)
    if (isNetworkError(e)) {
      this.setState({
        error: types.ERROR_NETWORK_CONNECTION
      })
    }
    // default error resolve to unknown error
    if (!this.state.error) {
      this.setState({
        error: types.ERROR_UNKNOWN
      })
    }
  }

  async _downloadStream (fileKey, fileID, ownerToken) {
    const url = await this._fetchDownloadUrl(fileKey, ownerToken)
    const filereq = await axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {
        if (fileID) {
          this.setFileState(fileID, { bytesDownloaded: progressEvent.loaded })
        }
      }
    })
    if (fileID) {
      this.setFileState(fileID, { bytesDownloaded: this.state.files[fileID].size })
    }
    return this._keyScheduler.decryptContentStream(blobStream(filereq.data))
  }

  async _downloadSw (fileKey, fileID, saveFile) {
    // init
    const url = await this._fetchDownloadUrl(fileKey)
    const start = Date.now()
    const onprogress = p => {
      this.setFileState(fileID, { bytesDownloaded: p })
    }
    try {
      const info = {
        request: 'init',
        id: `${this.key}/${fileID}`,
        url: url,
        key: this._keyScheduler.contentKey,
        type: this.files[fileID].type,
        size: this.files[fileID].size,
        name: this.files[fileID].name
      }

      await this._sendMessageToSw(info)
      onprogress(0)

      const downloadUrl = `/api/download/${info.id}`
      // Only set cache item for preview case
      if (!saveFile && !this.files[fileID].cache && fileID) {
        this.setFileState(fileID, { cache: downloadUrl })
      }
      if (saveFile) {
        saveFileWithURL(downloadUrl)
      }

      let prog = 0
      let hangs = 0
      while (prog < this.files[fileID].size) {
        const msg = await this._sendMessageToSw({
          request: 'progress',
          id: info.id
        })
        if (msg.progress === prog) {
          hangs++
        } else {
          hangs = 0
        }
        if (hangs > MAXIMUM_HANG_SECOND) {
          // TODO: On Chrome we don't get a cancel
          // signal so one is indistinguishable from
          // a hang. We may be able to detect
          // which end is hung in the service worker
          // to improve on this.
          const e = new Error('hung download')
          e.duration = Date.now() - start
          e.size = this.files[fileID].size
          e.progress = prog
          this.setState({
            error: types.ERROR_NETWORK_CONNECTION
          })
          throw e
        }
        prog = msg.progress
        onprogress(prog)
        await delay(1000)
      }
    } catch (e) {
      console.error(e)
      if (e === 'cancelled' || e.message === '400') {
        this.cancelDownload()
        throw new Error(0)
      }
      this.setState({
        error: types.ERROR_UNKNOWN
      })
      throw e
    }
  }

  async _downloadZipSw (name, files) {
    const urls = await Promise.all(files.map((file) => this._fetchDownloadUrl(file.key)))
    try {
      const info = {
        request: 'init',
        id: `${this.key}/download-archive`,
        urls,
        files,
        key: this._keyScheduler.contentKey,
        type: 'download-archive',
        name
      }

      await this._sendMessageToSw(info)

      const downloadUrl = `/api/download/${info.id}`
      saveFileWithURL(downloadUrl)
    } catch (e) {
      console.error(e)
      if (e === 'cancelled' || e.message === '400') {
        this.cancelDownload()
        throw new Error(0)
      }
      this.setState({
        error: types.ERROR_UNKNOWN
      })
      throw e
    }
  }

  _sendMessageToSw (msg) {
    return new Promise((resolve, reject) => {
      const channel = new MessageChannel()

      channel.port1.onmessage = function (event) {
        if (event.data === undefined) {
          reject(Error('bad response from serviceWorker'))
        } else if (event.data.error !== undefined) {
          reject(event.data.error)
        } else {
          resolve(event.data)
        }
      }
      navigator.serviceWorker.controller.postMessage(msg, [channel.port2])
    })
  }
}

function saveFileWithURL (url, download) {
  const a = document.createElement('a')
  a.href = url
  if (download) {
    a.download = download
  }
  document.body.appendChild(a)
  a.click()
  a.remove()
}
