import base64url from 'base64url'

const encoder = new TextEncoder()

/**
 * Signs the payload using HS256 algorithm with a webcrypto CryptoKey.
 *
 * @param {object} payload payload to sign
 * @param {CryptoKey} secret signing secret key
 */
export const signJWT = async function (payload, secret) {
  const header = { typ: 'JWT', alg: 'HS256' }
  const headerJSON = JSON.stringify(header)
  const payloadJSON = JSON.stringify(payload)

  const signInput = base64url(encoder.encode(headerJSON)) + '.' +
    base64url(encoder.encode(payloadJSON))
  const sig = await crypto.subtle.sign(
    {
      name: 'HMAC',
      hash: 'SHA-256'
    },
    secret,
    encoder.encode(signInput)
  )
  return signInput + '.' + base64url(sig)
}
