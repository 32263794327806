import axios from 'axios'
import PCancelable from 'p-cancelable'

import { toUint8Array } from './utils'

export default class BlobUploader {
  constructor (opts) {
    const defaults = {
      requestUploadHandler: () => {}
    }

    this.opts = {
      ...defaults,
      ...opts
    }
  }

  get _requestUpload () {
    return this.opts.requestUploadHandler
  }

  uploadStream (filesize, contentType, stream, progressHandler) {
    if (!progressHandler) {
      progressHandler = () => {}
    }
    return new PCancelable((resolve, reject, onCancel) => {
      this._uploadStreamInner(filesize, contentType, stream, onCancel, progressHandler).then(resolve, reject)
    })
  }

  async _uploadStreamInner (filesize, contentType, stream, onCancel, progressHandler) {
    let uploaded = 0
    progressHandler({ uploaded })
    const onChunkProgress = progress => progressHandler({ uploaded: uploaded + progress.uploaded })

    const startMeta = await this._requestUpload({ size: filesize, content_type: contentType })
    const file = startMeta.file
    onCancel(() => {
      this._requestUpload({ file, aborted: true })
    })

    const etag = {}
    let partNumber = 1
    // use server chunksize if given, 5 MiB if not
    const chunkSize = startMeta.chunk_size ? startMeta.chunk_size : 1048576 * 5
    const reader = stream.getReader()
    let state = await reader.read()
    let parts = []
    let len = 0
    while (!state.done) {
      parts.push(state.value)
      len += state.value.length
      if (len > chunkSize) {
        const result = await this._uploadChunk(file, toUint8Array(parts, len), partNumber, onCancel, onChunkProgress)
        etag[partNumber] = result
        uploaded += len
        partNumber += 1
        parts = []
        len = 0
      }
      state = await reader.read()
    }
    if (len > 0) {
      const result = await this._uploadChunk(file, toUint8Array(parts, len), partNumber, onCancel, onChunkProgress)
      etag[partNumber] = result
      partNumber += 1
      uploaded += len
    }
    // end
    const completeMeta = await this._requestUpload({ file, etag, completed: true })
    progressHandler({ uploaded })
    return {
      key: file,
      digest: completeMeta.etag
    }
  }

  async _uploadChunk (file, chunk, partNumber, onCancel, progressHandler) {
    const source = axios.CancelToken.source()
    onCancel(() => {
      source.cancel()
    })

    const len = chunk.length

    const uploadMeta = await this._requestUpload({ size: len, file, part: partNumber })
    const uploadResponse = await axios({
      url: uploadMeta.url,
      method: 'put',
      headers: {
        'content-type': 'application/octet-stream'
      },
      data: chunk.buffer,
      onUploadProgress: (progressEvent) => {
        progressHandler({ uploaded: progressEvent.loaded })
      },
      cancelToken: source.token
    })
    // Update Progress if onUploadProgress callback do not work
    if (uploadResponse.status === 200) {
      progressHandler({ uploaded: len })
    }
    return uploadResponse.headers.etag
  }
}
