import { useEffect, useCallback } from 'react'
import { useLocation, Route, Router, Redirect } from 'wouter'
import { useSelector } from 'react-redux'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'

import { getCurrentManager } from '@/core'
import { logEvent } from '@/utils'
import { managerSelector } from '@/stores/manager'
import Header from '@/components/header'
import ActiveLink from '@/components/routing/active-link'
import Helmet from '@/components/helmet'
import ManageFileItemList from './manage-file-item-list'
import './style'

const OwnerView = () => {
  // `bundles` is in Array format
  const state = useSelector(managerSelector)

  const fetchLocalActiveBundles = useCallback(() => getCurrentManager().fetchLocalActiveBundles(), [])
  const fetchRemoteBundles = useCallback(() => getCurrentManager().fetchRemoteBundles(), [])

  const hasLocal = state.hasActiveLocalBundles
  const [location] = useLocation()

  useEffect(() => {
    logEvent("Encl_manageView")
  }, [])

  return (
    <>
      <Helmet>
        <title>My Transfers</title>
      </Helmet>
      <Header isOwner={true} createTransfer={true} />
      <Container>
        <Row className="min-vh-100">
          <Col>
            <Row noGutters>
              <Col>
                <Row id="title" className="my-5">
                  <Col>
                    <div className="h3 font-weight-bold">Transfer History</div>
                    <div className="small text-darkgray">Transfer history is saved securely to this device.</div>
                  </Col>
                </Row>
                <Row id="nav" className="border-bottom">
                  <Col className="px-0">
                    <Nav className="nav-underline" activeKey={location}>
                      <Nav.Item>
                        <Nav.Link className="px-0 mx-3" as={ActiveLink} href="/manager/active">
                          <span className="small">Active</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link className="px-0 mx-3" as={ActiveLink} href="/manager/expired">
                          <span className="small">Expired</span>
                        </Nav.Link>
                      </Nav.Item>
                      { hasLocal &&
                        <Nav.Item>
                          <Nav.Link className="px-0 mx-3" as={ActiveLink} href="/manager/local">
                            <span className="small">This Device</span>
                          </Nav.Link>
                        </Nav.Item>
                      }
                    </Nav>
                  </Col>
                </Row>
                <Row id="content" className="mb-3">
                  <Col>
                    <Router base="/manager" key="/manager">
                      <Route path="/">
                        <Redirect to="/active" replace={true} />
                      </Route>
                      <Route path="/active">
                        <BundleList bundles={state.bundles} keys={state.userActiveKeys} hook={fetchRemoteBundles} />
                      </Route>
                      <Route path="/expired">
                        <BundleList bundles={state.bundles} keys={state.userExpiredKeys} hook={fetchRemoteBundles} />
                      </Route>
                      <Route path="/local">
                        <BundleList bundles={state.bundles} keys={state.localActiveKeys} hook={fetchLocalActiveBundles} />
                      </Route>
                    </Router>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

const BundleList = (props) => {
  const { bundles, keys, hook } = props
  let bundleList = null
  if (keys) {
    bundleList = keys.map((key) => {
      return bundles[key]
    })
  }
  useEffect(() => {
    hook()
  }, [hook])
  return <ManageFileItemList bundleList={bundleList} />
}

export default OwnerView
