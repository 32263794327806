import mimeTypes from './mimeTypes'
import * as types from './types'

export function getFileNameAndExtension (fullFileName) {
  const lastDot = fullFileName.lastIndexOf('.')
  // these count as no extension: "no-dot", "trailing-dot."
  if (lastDot === -1 || lastDot === fullFileName.length - 1) {
    return {
      name: fullFileName,
      extension: undefined
    }
  } else {
    return {
      name: fullFileName.slice(0, lastDot),
      extension: fullFileName.slice(lastDot + 1)
    }
  }
}

export function getFileType (file) {
  let fileExtension = file.name ? getFileNameAndExtension(file.name).extension : null
  fileExtension = fileExtension ? fileExtension.toLowerCase() : null

  if (file.type) {
    // if mime type is set in the file object already, use that
    return file.type
  } else if (fileExtension && mimeTypes[fileExtension]) {
    // else, see if we can map extension to a mime type
    return mimeTypes[fileExtension]
  } else {
    // if all fails, fall back to a generic byte stream type
    return 'application/octet-stream'
  }
}

export function concat (b1, b2) {
  const result = new Uint8Array(b1.length + b2.length)
  result.set(b1, 0)
  result.set(b2, b1.length)
  return result
}

export async function streamToArrayBuffer (stream, size) {
  const reader = stream.getReader()
  let state = await reader.read()

  if (size) {
    const result = new Uint8Array(size)
    let offset = 0
    while (!state.done) {
      result.set(state.value, offset)
      offset += state.value.length
      state = await reader.read()
    }
    return result.buffer
  }

  const parts = []
  let len = 0
  while (!state.done) {
    parts.push(state.value)
    len += state.value.length
    state = await reader.read()
  }
  let offset = 0
  const result = new Uint8Array(len)
  for (const part of parts) {
    result.set(part, offset)
    offset += part.length
  }
  return result.buffer
}

export function delay (delay = 100) {
  return new Promise(resolve => setTimeout(resolve, delay))
}

export function timeUsed (prevTime) {
  return (new Date()).getTime() - prevTime
}

export function toUint8Array (array, len) {
  const result = new Uint8Array(len)
  let offset = 0
  for (const part of array) {
    result.set(part, offset)
    offset += part.length
  }
  return result
}

export function browserName () {
  try {
    // order of these matters
    if (/firefox/i.test(navigator.userAgent)) {
      return 'firefox'
    }
    if (/edge/i.test(navigator.userAgent)) {
      return 'edge'
    }
    // Edge in iOS contains "EdgiOS", in which case it should return to Safari as the engine is in Webkit for iOS browser
    if (/edg(?!iOS)/i.test(navigator.userAgent)) {
      return 'edgium'
    }
    if (/trident/i.test(navigator.userAgent)) {
      return 'ie'
    }
    if (/chrome/i.test(navigator.userAgent)) {
      return 'chrome'
    }
    if (/safari/i.test(navigator.userAgent)) {
      return 'safari'
    }
    return 'other'
  } catch (e) {
    return 'unknown'
  }
}

export function isQuotaExceeded (e) {
  if (!e.response) {
    return false
  }
  const data = e.response.data
  return e.response.status === 401 && data && data.error === 'quota exceeded'
}

export function isThrowableError (e) {
  return !e || [types.ERROR_UNKNOWN, types.ERROR_NETWORK_CONNECTION].includes(e)
}

// Return file instance from FileSystemFileEntry
export async function readFileEntry (entry) {
  return new Promise((resolve, reject) => {
    entry.file((file) => {
      file.fullPath = entry.fullPath
      resolve(file)
    }, (error) => reject(error))
  })
}

// Return all files in the directory
export async function readDirectoryEntry (entry) {
  return new Promise((resolve, reject) => {
    const fileList = []
    const directoryReader = entry.createReader()
    directoryReader.readEntries(entries => {
      entries.forEach(item => {
        if (item.isDirectory) {
          const files = readDirectoryEntry(item)
          fileList.push(files)
        } else if (item.isFile) {
          const file = readFileEntry(item)
          fileList.push(file)
        } else {
          reject(new Error('file is neither file nor directory'))
        }
      })
      resolve(Promise.all(fileList))
    })
  })
}

// return JWT exp in second
export function jwtExpiry (token) {
  const split = token.split('.')
  return JSON.parse(atob(split[1])).exp
}

export function isJWTExpired (token) {
  const res = jwtExpiry(token) * 1000
  const currentTime = Date.now()
  if (res.exp < currentTime) {
    return true
  }
  return false
}

// check if token have a token structure
export function isToken (token) {
  if (!token || typeof token !== 'string' || token.split('.').length !== 3) {
    return false
  }
  return true
}
