import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import style from './style.scss'

const Footer = () => (
  <Row className={`mx-3 mb-4 ${style['footer']}`}>
    <Col xs="auto" className="align-self-end">
      <span className="small text-footer-black align-sub">Send files for free!</span>
    </Col>
    <Col xs="auto" className="align-self-end">
      <a href="https://about.encl.io/privacy" className="small text-footer-black align-sub">Privacy</a>
    </Col>
    <Col xs="auto" className="align-self-end">
      <a href="https://about.encl.io/terms" className="small text-footer-black align-sub">Terms</a>
    </Col>
    <Col xs="auto" className="align-self-end">
      <a href="mailto:report-abuse@encl.io" className="small text-footer-black align-sub">Report abuse</a>
    </Col>
  </Row>
)

export default Footer
