import classNames from 'classnames'
import ContentLayout from '@/components/content-layout'
import ErrorPane from '@/components/error-pane'

const ErrorPage = (props) => {
  const paneFrameClassName = classNames('align-self-center', 'order-first', 'order-md-last')
  return (
    <ContentLayout className={paneFrameClassName}>
      <ErrorPane error={props.error} />
    </ContentLayout>
  )
}

export default ErrorPage
