import { authSelector } from '@/stores/auth'
import { useSelector } from 'react-redux'

import { faRectangleWide } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import classNames from 'classnames'

import style from './style'

const CallToActionLabel = (props) => {
  const state = useSelector(authSelector)
  const spanClassName = classNames('fa-layers', 'fa-fw', 'fa-lg', 'ml-1', props.className)
  return (
    <>
      {!state.isAuthenticated &&
     <span className={spanClassName}>
       <FontAwesomeIcon icon={faRectangleWide} className="text-primary" />
       <span className={`fa-layers-text fa-inverse ${style['wording-transform']}`}>PRO</span>
     </span>
      }
    </>
  )
}

export default CallToActionLabel
