import './style'
import App from './components/app'
import { initAnalytics, initSentry, configureDayjs } from './utils'

export default App

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistration()
    .then((registration) => {
      registration.update()
    })
}

configureDayjs()
initAnalytics()
initSentry()
