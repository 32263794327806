import { createSlice, createSelector } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

export const uploaderSlice = createSlice({
  name: 'uploader',
  initialState: {},
  reducers: {
    setState: (state, action) => ({...state, ...action.payload})
  },
})

export const { setState } = uploaderSlice.actions

const stateSelector = state => state.uploader

// Derived state
export const uploaderSelector = createSelector(
  stateSelector,
  (state) => ({
    ...state,
    fileIDs: [...state.fileIDs].sort((a, b) => state.files[a].name.localeCompare(state.files[b].name)),
    expiresAt: dayjs().add(state.expiresIn, 'seconds'),
    filesCount: Object.values(state.files).length,
    bundleSize: Object.values(state.files).map(file => file.size).reduce((sum, current) => sum + current, 0)
  })
)

export const defaultTitle = (state) => {
  const { fileIDs } = state
  const size = fileIDs.length
  if (size === 0) {
    return 'Untitled'
  }
  if (size === 1) {
    return '1 file'
  }
  return `${size} files`
}

export default uploaderSlice.reducer
