import { useSelector, useDispatch } from 'react-redux'
import Modal from 'react-bootstrap/Modal'

import { userSelector, hideChangeBackground } from '@/stores/user'
import ChangeBackgroundPane from './pane'

const ChangeBackgroundModal = () => {
  const dispatch = useDispatch()
  const state = useSelector(userSelector)

  const onHide = () => {
    dispatch(hideChangeBackground())
  }

  return (
    <Modal
      show={state.changeBackgroundShown}
      onHide={onHide}
      size="md"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Change Background</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ChangeBackgroundPane />
      </Modal.Body>
    </Modal>
  )
}

export default ChangeBackgroundModal
