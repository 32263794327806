import { useRouter } from 'wouter'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { faExternalLink, faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DropdownIconToggle from '@/components/dropdown-icon-toggle'

import filesize from 'filesize'

import { getCurrentManager } from '@/core'
import { formatDate, logEvent } from '@/utils'

const ManageFileItemRow = (props) => {
  // Use router's hook instead of useRouter to avoid prefixing the link with router's basename
  const [,setLocation] = useRouter().hook()
  const bundle = props.bundle
  const formattedExpiresAt = bundle.expiresAt ? `expires on ${formatDate(bundle.expiresAt)}` : 'never expire'
  const expired = bundle.expiresAt && bundle.expiresAt <= Date.now()
  const hasView = !!bundle.url && !expired
  const revoke = () => {
    logEvent("Encl_revokeBundle")
    getCurrentManager().revoke(bundle.key)
  }

  const viewed = bundle.openCount > 0
  const downloaded = false

  const onClick = () => {
    logEvent("Encl_viewBundle")
    setLocation(bundle.url)
  }

  const statusIcon = (
    <span className="fa-layers fa-fw fa-2x">
      {downloaded &&
       <>
         <FontAwesomeIcon icon={faCheck} className="text-primary" transform="left-2" />
         <FontAwesomeIcon icon={faCheck} className="text-primary" transform="right-2" />
       </>
      }
      {viewed && !downloaded &&
       <FontAwesomeIcon icon={faCheck} className="text-primary" />
      }
      {!viewed && !downloaded &&
       <FontAwesomeIcon icon={faCheck} className="text-lightgray" />
      }
    </span>
  )

  return (
    <ListGroup.Item className="border-0 px-0">
      <Row className="align-items-center">
        <Col bsPrefix="col-auto" className="pr-0">
          {statusIcon}
        </Col>
        <Col className="overflow-hidden">
          {/* TODO: Handle overflowing in responsive */}
          <div className="font-weight-bold text-darkblue" title={bundle.title}>{bundle.title}</div>
          <div className="small text-darkgray">
            {filesize(bundle.size)} <span className="text-border-color">│</span> {formattedExpiresAt}
            <span class="d-none d-sm-inline"> <span className="text-border-color">│</span> max {bundle.openLimit} view</span>
          </div>
        </Col>
        <Col bsPrefix="col-auto" className="d-none d-sm-flex flex-column align-items-center">
          <div className="font-weight-bold text-darkblue">{bundle.openCount || '-'}</div>
          <div className="small text-darkgray">Views</div>
        </Col>
        <Col bsPrefix="col-auto" className="px-0 d-none d-sm-block">
          <Button variant="link" className={hasView ? "" : "disabled"} onClick={onClick}>
            <FontAwesomeIcon size="lg" icon={faExternalLink} className="text-darkgray" />
          </Button>
        </Col>
        <Col bsPrefix="col-auto" className="px-0">
          <Dropdown>
            <DropdownIconToggle id="dropdown">
              <Button variant="link">
                <FontAwesomeIcon size="lg" icon={faEllipsisH} className="text-darkgray" />
              </Button>
            </DropdownIconToggle>

            <Dropdown.Menu align="right">
              <Dropdown.Item className="disabled d-sm-none">
                <Row>
                  <Col>
                    <div class="text-darkgray">
                      <span className="font-weight-bold">{bundle.openCount || '-'}</span>
                      /{bundle.openLimit} views
                    </div>
                  </Col>
                </Row>
              </Dropdown.Item>
              <Dropdown.Divider className="d-sm-none" />
              <Dropdown.Item className={hasView ? "d-block d-sm-none" : "disabled d-sm-none" } eventKey="1" onClick={onClick}>View</Dropdown.Item>
              <Dropdown.Item eventKey="2" className={expired ? "disabled" : ""} onClick={revoke}>Revoke</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    </ListGroup.Item>
  )
}

export default ManageFileItemRow
