export const MODE_RAW_SECRET = 1
export const MODE_PASSWORD = 2

export const BURN_AFTER_READ = 1

export const ERROR_UNKNOWN = 'ERROR_UNKNOWN'
export const ERROR_NOT_FOUND = 'ERROR_NOT_FOUND'
export const ERROR_EXPIRED = 'ERROR_EXPIRED'
export const ERROR_PASSWORD_REQUIRED = 'ERROR_PASSWORD_REQUIRED'
export const ERROR_PASSWORD_INCORRECT = 'ERROR_PASSWORD_INCORRECT'
export const ERROR_INCOMPATIBLE = 'ERROR_INCOMPATIBLE'
export const ERROR_QUOTA_EXCEEDED = 'ERROR_QUOTA_EXCEEDED'
export const ERROR_FILE_TOO_LARGE = 'ERROR_FILE_TOO_LARGE'
export const ERROR_UPLOAD_FILE_NOT_ALLOWED = 'ERROR_UPLOAD_FILE_NOT_ALLOWED'
export const ERROR_VIEW_FILE_NOT_ALLOWED = 'ERROR_VIEW_FILE_NOT_ALLOWED'
export const ERROR_NETWORK_CONNECTION = 'ERROR_NETWORK_CONNECTION'
export const ERROR_DOWNLOAD_NOT_AVAILABLE = 'ERROR_DOWNLOAD_NOT_AVAILABLE'
export const ERROR_BURN_AFTER_READ_WARNING = 'ERROR_BURN_AFTER_READ_WARNING'
export const ERROR_PASSWORD_REQUIRED_BURN_AFTER_READ = 'ERROR_PASSWORD_REQUIRED_BURN_AFTER_READ'
export const ERROR_PASSWORD_INCORRECT_BURN_AFTER_READ = 'ERROR_PASSWORD_INCORRECT_BURN_AFTER_READ'
