import Client from './client'
import { createDefaultStore } from './store'
import { hasStorage } from './features'
import { jwtExpiry, isJWTExpired, isToken } from './utils'

const accessTokenKey = 'Encl_accessToken'
const refreshTokenKey = 'Encl_refreshToken'

const initialState = {
  userProfile: {},
  accessToken: '',
  refreshToken: '',
  isAuthenticated: false
}

function storage () {
  if (hasStorage('localStorage')) {
    return window.localStorage
  } else if (hasStorage('sessionStorage')) {
    return window.sessionStorage
  }
  return undefined
}

export default class Auth {
  constructor (opts) {
    const defaults = {
      store: createDefaultStore()
    }

    this.opts = {
      ...defaults,
      ...opts
    }

    this._store = this.opts.store(initialState)
    this._client = new Client({
      ...opts,
      accessTokenGenerator: () => this.accessToken()
    })
    this.syncState()
    this.queueRefresh()
    try {
      this.userProfile()
    } catch (err) {
      console.error(err)
    }
  }

  get state () {
    return this._store.getState()
  }

  setState (state) {
    return this._store.setState(state)
  }

  oauthURL (state, codeChallenge) {
    return this._client.oauthURL(state, codeChallenge)
  }

  syncState () {
    this.accessToken()
    this.refreshToken()
  }

  isAuthenticated () {
    return this.state.isAuthenticated
  }

  accessToken () {
    const store = storage()
    if (store) {
      const accessToken = store.getItem(accessTokenKey)
      if (accessToken && isToken(accessToken)) {
        if (!isJWTExpired(accessToken)) {
          this.setAccessToken(accessToken)
          return accessToken
        }
      }
      store.removeItem(accessTokenKey)
    }
    this.setAccessToken('')
    return ''
  }

  refreshToken () {
    const store = storage()
    if (store) {
      const refreshToken = store.getItem(refreshTokenKey)
      if (refreshToken && refreshToken !== 'undefined') {
        this.setRefreshToken(refreshToken)
        return refreshToken
      } else {
        store.removeItem(refreshTokenKey)
      }
    }
    this.setRefreshToken('')
    return ''
  }

  setAccessToken (token) {
    if (this.state.accessToken !== token) {
      this.setState({
        accessToken: token,
        isAuthenticated: !!token
      })
      const store = storage()
      if (store) {
        store.setItem(accessTokenKey, token)
      }
    }
  }

  setRefreshToken (token) {
    if (this.state.refreshToken !== token) {
      this.setState({
        refreshToken: token
      })
      const store = storage()
      if (store) {
        store.setItem(refreshTokenKey, token)
      }
    }
  }

  timeTillRefresh () {
    const accessToken = this.accessToken()
    const refreshBuffer = 60000 // time to refresh before actual timeout
    if (accessToken) {
      return Math.max(jwtExpiry(accessToken) * 1000 - Date.now() - refreshBuffer, 0)
    }
    // if dont have accessToken, refresh immediately
    return 0
  }

  async refresh () {
    const refreshToken = this.refreshToken()
    if (refreshToken) {
      try {
        this.setAccessToken('') // clear access token before refresh
        const res = await this._client.refreshToken(refreshToken)
        this.setAccessToken(res.access_token)
        this.setRefreshToken(res.refresh_token)
        this.queueRefresh()
      } catch (e) {
        await this.removeAccessToken()
      }
    }
  }

  queueRefresh () {
    const refreshToken = this.refreshToken()
    if (refreshToken) {
      setTimeout(() => this.refresh(), this.timeTillRefresh())
    }
  }

  async removeAccessToken () {
    this.setAccessToken('')
    this.setRefreshToken('')
    await this.userProfile()
  }

  async finalize (state, code, codeVerifier) {
    const resp = await this._client.authToken(state, code, codeVerifier)

    if (resp.state !== state) {
      throw Error('oauth state do not match')
    }
    this.setAccessToken(resp.access_token)
    this.setRefreshToken(resp.refresh_token)
    this.queueRefresh()
    this.userProfile()
  }

  async userProfile () {
    const userProfile = await this._client.userProfile()
    this.setState({
      userProfile
    })
  }

  features () {
    const userProfile = this.state.userProfile
    if (userProfile) {
      const features = userProfile.features
      if (features) {
        return features
      }
      return {}
    }
  }

  name () {
    const userProfile = this.state.userProfile
    if (userProfile && userProfile.name) {
      return userProfile.name
    }
    // fallback to email
    return this.email()
  }

  email () {
    const userProfile = this.state.userProfile
    if (userProfile) {
      return userProfile.email
    }
    return ''
  }
}
