import { v4 as uuid } from 'uuid'

import { hasStorage } from './features'

const DEVICE_ID_KEY = 'encl.deviceID'

let storage

if (hasStorage('localStorage')) {
  storage = window.localStorage
} else if (hasStorage('sessionStorage')) {
  storage = window.sessionStorage
}

export function getDeviceID () {
  let val = storage.getItem(DEVICE_ID_KEY)
  if (!val) {
    val = uuid()
    storage.setItem(DEVICE_ID_KEY, val)
  }
  return val
}
