import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import style from './style.scss'
import { logEvent } from '@/utils'

const TryEnclButton = () => {
  const onClick = () => {
    logEvent('Encl_tryEnclButton')
  }

  return (
    <a href="/" onClick={onClick}>
      <Row>
        <Col xs="12" className="pr-0">
          <span className="mr-1">Sent with</span>
          <img className={`${style['icon-full']} align-bottom`} alt="" src="/assets/app_icon_full_blue.svg" />
        </Col>
      </Row>
    </a>
  )
}

export default TryEnclButton
