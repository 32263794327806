import { createSlice, createSelector } from '@reduxjs/toolkit'

export const viewerSlice = createSlice({
  name: 'viewer',
  initialState: {
    fileIDs: [],
    files: {}
  },
  reducers: {
    setState: (state, action) => ({...state, ...action.payload})
  },
})

export const { setState } = viewerSlice.actions

const stateSelector = state => state.viewer

// Derived state
export const viewerSelector = createSelector(
  stateSelector,
  (state) => ({
    ...state,
    fileIDs: [...state.fileIDs].sort((a, b) => state.files[a].name.localeCompare(state.files[b].name)),
    filesCount: Object.values(state.files).length,
    bundleSize: Object.values(state.files).map(file => file.size).reduce((sum, current) => sum + current, 0)
  })
)

export default viewerSlice.reducer
