import { Component } from 'preact'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Provider } from 'react-redux'
import { Route, Switch } from 'wouter'

import Col from 'react-bootstrap/Col'
import AppLayout from '@/components/app-layout'
import PaneFrame from '@/components/pane-frame'
import DropZone from '@/components/dropzone'
import ErrorPage from '@/components/error-page'
import ManageView from '@/components/manage-view'
import Auth from '@/components/auth'
import ChangeBackgroundModal from '@/components/change-background-modal'

import { types, getSupported } from '@/core'
import ViewerPage from '@/routes/viewer-page'
import UploaderPage from '@/routes/uploader-page'
import store from '../stores'

export default class App extends Component {
  state = {
    loaded: false
  }

  constructor(props) {
    super(props)
    this.loaded = this.loaded.bind(this)
  }

  loaded() {
    this.setState({ loaded: true })
  }

  componentDidMount() {
    window.addEventListener('load', this.loaded)
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.loaded)
  }

  render() {
    return (
      <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
          <div id="app">
            <CompatibilityGuard>
              <Route path="/" component={DropZone} />
              <Switch>
                <Route path="/manager/:rest*">
                  <ManageView />
                </Route>
                <Route path="/auth">
                  <Auth />
                </Route>
                <Route path="/:rest*">
                  {/* TODO: Set correct class (viewer-container) for viewer page to have smaller height */}
                  <Switch>
                    <Route path="/">
                      <UploaderPage />
                    </Route>
                    <Route path="/t/:id" component={ViewerPage} />
                    <Route>
                      <ErrorPage error={types.ERROR_NOT_FOUND} />
                    </Route>
                  </Switch>
                </Route>
              </Switch>
              <ChangeBackgroundModal />
            </CompatibilityGuard>
          </div>
        </DndProvider>
      </Provider>
    )
  }
}

const CompatibilityGuard = (props) => {
  if (!getSupported()) {
    return (
      <AppLayout rowClassName="flex-column flex-md-row">
        <Col />
        <PaneFrame className="align-self-center offset-end-md-1">
          <ErrorPage error={types.ERROR_INCOMPATIBLE} />
        </PaneFrame>
      </AppLayout>
    )
  }
  return (<>
    {props.children}
  </>)
}
