import classNames from 'classnames'
import Col from 'react-bootstrap/Col'

import PaneFrame from '@/components/pane-frame'

const HeroPane = (props) => {
  const heroPaneClassName = classNames(props.children ? '' : 'd-none d-md-block', 'pb-3', props.className)
  return (
    <>
      <Col md={{ span: 4, offset: props.offset ? 1 : 0 }} className={heroPaneClassName}>
        {props.children}
      </Col>
      <Col className="d-none d-md-block" />
    </>
  )
}

const ContentPane = (props) => {
  const contentPaneClassName = classNames('offset-end-md-1', props.className)
  return (
    <PaneFrame hideBoxShadowInMobile panelClassName={props.panelClassName} className={contentPaneClassName}>{props.children}</PaneFrame>
  )
}

const ContentLayout = (props) => {
  return (
    <>{props.children}</>
  )
}

ContentLayout.HeroPane = HeroPane
ContentLayout.ContentPane = ContentPane

export default ContentLayout
