import { createSlice, createSelector } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    accessToken: '',
    refreshToken: '',
    isAuthenticated: false
  },
  reducers: {
    setState: (state, action) => ({...state, ...action.payload})
  },
})

export const { setState } = authSlice.actions

const stateSelector = state => state.auth

// Derived state
export const authSelector = createSelector(
  stateSelector,
  (state) => ({
    ...state,
  })
)

export default authSlice.reducer
