import { useState, useEffect } from 'react'
import { useLocation } from 'wouter'
import base64url from 'base64url'
import { hasStorage, getCurrentAuth, types } from '@/core'
import { logEvent, sha256 } from '@/utils'

import Spinner from 'react-bootstrap/Spinner'
import ErrorPage from '@/components/error-page'

const storage = () => {
  if (hasStorage('sessionStorage')) {
    return window.sessionStorage
  } else if (hasStorage('localStorage')) {
    return window.localStorage
  }
  throw Error('storage not available')
}

const Auth = () => {
  const [error, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [, setLocation] = useLocation()
  
  useEffect(() => {
    const auth = getCurrentAuth()
    const redirectToAuth = async () => {
      const state = new Uint8Array(16)
      crypto.getRandomValues(state)
      const stateString = base64url(Buffer.from(state))
      
      const rawVerifier = new Uint8Array(16)
      crypto.getRandomValues(rawVerifier)
      const codeVerifier = base64url(Buffer.from(rawVerifier))
      const codeChallenge = await sha256(codeVerifier)
  
      storage().setItem('state', stateString)
      storage().setItem('code_verifier', codeVerifier)
      window.location.replace(auth.oauthURL(stateString, codeChallenge))
    }
  
    const finalizeAuth = async (state, code) => {
      try {
        // check state
        const storedState = storage().getItem('state')
        const codeVerifier = storage().getItem('code_verifier')
        if (state !== storedState) {
          throw Error('oauth state do not match')
        } else if (!codeVerifier) {
          throw Error('code verifier do not exist')
        }
        await auth.finalize(state, code, codeVerifier)
        logEvent('Encl_authFinished')
        setLocation('/')
      } catch (e) {
        console.error(e)
        setError(e)
      }
    }

    try {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      if (urlParams.has('state')) {
        const err = urlParams.get('error')
        if (err) {
          const errorDescription = urlParams.get('error_description')
          // TODO: handle different OAuth error
          throw new Error(`${err} ${errorDescription}`)
        }
        const state = urlParams.get('state')
        const code = urlParams.get('code')
        finalizeAuth(state, code)
      } else {
        logEvent('Encl_authStarted')
        redirectToAuth()
      }
    } catch (e) {
      console.error(e)
      setError(e)
    }
    setTimeout(() => setLoading(true), 1000)
  }, [setLocation])


  if (error) {
    return <ErrorPage error={types.ERROR_UNKNOWN} />
  }
  if (isLoading) {
    return (
      <div className="h-100 text-center align-middle">
        <Spinner animation="border" variant="primary" />
      </div>
    )
  }
  return
}

export default Auth
