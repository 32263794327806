import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import Header from '@/components/header'
import Background from '../background'
import Footer from '../footer'
import LoginPane from '@/components/login-pane'

import { useSearchParam } from 'react-use'

const AppLayout = (props) => {
  const pro = useSearchParam('pro')
  const containerClassName = props.containerClassName ? props.containerClassName : ''
  const rowClassName = props.rowClassName ? props.rowClassName : ''
  return (<>
    <Background background={props.background} />
    <div className="d-flex flex-column h-100" onPaste={props.onPaste}>
      <Header isOwner={true} createTransfer={false} />
      {/* height: fit-content is necessary for Safari to fill up the height of the container */}
      <Container fluid className={`d-flex flex-fill h-fit-content ${containerClassName}`}>
        <Row className={`flex-fill ${rowClassName}`}>
          {props.children}
        </Row>
      </Container>
      <Footer />
      {pro === '1' &&
       <LoginPane />
      }
    </div>
  </>)
}

export default AppLayout
