import { forwardRef } from 'react'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'

const ButtonToggle = forwardRef(({ children, onClick }, ref) => (
  <Button
    variant="link"
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    {children}
  </Button>
))

const DropdownIconToggle = (props) => {
  return (
    <Dropdown.Toggle as={ButtonToggle} {...props}>
      {props.children}
    </Dropdown.Toggle>
  )
}

export default DropdownIconToggle
