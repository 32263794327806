import Dexie from 'dexie'

export const distantFuture = 8640000000000000
export const distantPast = -8640000000000000

let db = null

export function getDB () {
  if (!db) {
    db = new Dexie('encl-manager')
    db.version(1).stores({
      bundles: '&key,expiresAt'
    })
  }
  return db
}

export function isIDBMissing (e) {
  if (e.name === 'OpenFailedError') {
    return true
  }
  return false
}
