import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'

import ManageFileItemRow from './manage-file-item-row'

import style from './manage-file-item-list.scss'

const ManageFileItemList = (props) => {
  const { bundleList } = props
  if (bundleList && bundleList.length === 0) {
    return (
      <div className={style['empty-description']}>
        <Row className="justify-content-center">
          <Col xs="auto">
            <div className="h3 font-weight-bold text-darkblue text-center">
              Simple and secure file transfer.
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            <div className="small text-darkgray">
              You can view recently created link here.
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5 mb-3">
          <Col xs="auto">
            <Button as="a" href="/" variant="primary" className="font-weight-bold">Create Transfer</Button>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            <a href="https://about.encl.io/">Learn more</a>
          </Col>
        </Row>
      </div>
    )
  }

  const listItems = bundleList ? bundleList.map(bundle => (
    <ManageFileItemRow bundle={bundle} key={bundle.key} />
  )) : []

  return (
    <Row>
      <Col xs={12}>
        <ListGroup className="border-0">
          {listItems}
        </ListGroup>
      </Col>
    </Row>
  )
}

export default ManageFileItemList
