import amplitude from 'amplitude-js'
import base64url from 'base64url'
import * as Sentry from "@sentry/react"
import ReactGA from 'react-ga'

import styleVariables from '@/style'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import duration from 'dayjs/plugin/duration'

dayjs.extend(relativeTime, {
  thresholds: [
    { l: 's', r: 1 },
    { l: 'm', r: 1 },
    { l: 'mm', r: 59, d: 'minute' },
    { l: 'h', r: 1 },
    { l: 'hh', r: 23, d: 'hour' },
    { l: 'd', r: 1 },
    { l: 'dd', r: 89, d: 'day' },
    { l: 'M', r: 1 },
    { l: 'MM', r: 11, d: 'month' },
    { l: 'y' },
    { l: 'yy', d: 'year' }
  ]
})
dayjs.extend(updateLocale)
dayjs.extend(duration)

export const configureDayjs = () => {
  dayjs.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past:   '%s ago',
      s  : 'a few seconds',
      ss : '%d seconds',
      m:  'a minute',
      mm: '%d minutes',
      h:  'an hour',
      hh: '%d hours',
      d:  '%d day',
      dd: '%d days',
      w:  'a week',
      ww: '%d weeks',
      M:  'a month',
      MM: '%d months',
      y:  'a year',
      yy: '%d years'
    }
  })
}

export const humanizeSeconds = (value) => dayjs.duration(value, 'seconds').humanize()

export const formatDate = (d) => dayjs(d).format('DD/MM/YYYY')

export const formatRelativeDate = (d) => dayjs(d).fromNow()

// Platform detection
// https://stackoverflow.com/questions/19877924/what-is-the-list-of-possible-values-for-navigator-platform-as-of-today
export const isWindows = () => {
  return /Win/i.test(navigator.platform) && !isMobile()
}

export const isMac = () => {
  return /Mac/i.test(navigator.platform) && !isMobile()
}

export const isLinux = () => {
  return /Linux/i.test(navigator.platform) && !isMobile()
}

// Check if the device is in mobile
// Reference: https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#Mobile.2C_Tablet_or_Desktop
export const isMobile = () => {
  return /Mobi/i.test(navigator.userAgent)
}

// Hackish solution for showing keyboard in mobile browser(Both Android and iOS platform)
// Injects a hidden input element, focus on that to force showing soft keyboard
// For iOS this solves the keyboard is not shown when switching on the input element.
// For Android this solves the keyboard is not shown when switching on the input element after the first time.
// Reference: https://stackoverflow.com/questions/54424729/ios-show-keyboard-on-input-focus/55425845#55425845
export const focusAndOpenKeyboard = () => {
  if (isMobile()) {
    const elementId = '__tempEl__'
    const __tempEl__ = document.createElement('input')
    __tempEl__.id = elementId
    __tempEl__.style.position = 'absolute'
    __tempEl__.style.top = 0
    __tempEl__.style.left = 0
    __tempEl__.style.height = 0
    __tempEl__.style.opacity = 0
    // Put this temp element as a child of the page <body> and focus on it
    document.body.appendChild(__tempEl__)
    __tempEl__.focus()

    setTimeout(() => {
      document.body.removeChild(__tempEl__)
    }, 100)
  }
}

export const initAnalytics = () => {
  if (location.host === process.env.HOSTNAME) {
    amplitude.getInstance().init(process.env.AMPLITUDE_API_KEY)
    ReactGA.initialize(process.env.GOOGLE_ANALYTICS_TRACKING_ID)
  }
}

export const logEvent = async (event, data, callback) => {
  if (location.host === process.env.HOSTNAME) {
    amplitude.getInstance().logEvent(event, data, callback)
    ReactGA.event({
      category: 'page event',
      action: event
    })
  }
}

export const initSentry = () => {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [],
    ignoreErrors: [
      // service worker related
      'InvalidStateError: Failed to update a ServiceWorker',
      "undefined is not an object (evaluating 'e.update')",
      'AbortError: Failed to register a ServiceWorker',
      'InvalidStateError: newestWorker is null',
      // browsers, plugins
      'vid_mate_check'
    ],
    beforeSend(event) {
      event.request.url = event.request.url.split('#')[0]
      return event
    },
    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb.category == 'navigation' && breadcrumb.data) {
        // mask fragment in navigation
        if (breadcrumb.data.from) {
          breadcrumb.data.from = breadcrumb.data.from.split('#')[0]
        }
        if (breadcrumb.data.to) {
          breadcrumb.data.to = breadcrumb.data.to.split('#')[0]
        }
      } else if (breadcrumb.category == 'xhr' && breadcrumb.data) {
        // mask s3 signature
        if (breadcrumb.data.url && breadcrumb.data.url.includes("amazonaws")) {
          breadcrumb.data.url = breadcrumb.data.url.split('?')[0]
        }
      }
      return breadcrumb
    }
  })
}

// return base64url encoded digest
export const sha256 = async (message) => {
  const msgUint8 = new TextEncoder().encode(message)
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8)
  return base64url(Buffer.from(hashBuffer))
}

export const parseIntRadix10 = (string) => {
  return parseInt(string, 10)
}

export const mediaBreakpointUp = (breakpoint) => {
  if (breakpoint === 'md') {
    return document.body.clientWidth > parseIntRadix10(styleVariables.mdWidthThreshold)
  } else if (breakpoint === 'sm') {
    return document.body.clientWidth > parseIntRadix10(styleVariables.smWidthThreshold)
  }
}

export const saveClipboard = (data) => {
  const dummy = document.createElement('input')
  document.body.appendChild(dummy)
  dummy.value = data
  dummy.select()
  const success = document.execCommand('copy')
  document.body.removeChild(dummy)
  return success
}

export const shareLink = async (data) => {
  try {
    await navigator.share({
      title: data.title,
      text: data.text,
      url: data.url
    })
  } catch (e) {
    if (e instanceof TypeError) {
      console.log('Browser is not compatiable for share API')
    } else if (e.name === 'AbortError') {
      console.log('Cancelled share action')
    } else {
      console.error(e)
    }
  }
}
