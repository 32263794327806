import { useLocation } from 'wouter'
import Modal from 'react-bootstrap/Modal'

import GoogleSignInButton from './google-sign-in-button'

import style from './style'

const LoginPane = () => {
  const [, setLocation] = useLocation()
  const onHide = () => {
    setLocation(`${location.origin}${location.pathname}${location.hash}`)
  }

  const signIn = () => {
    setLocation('/auth')
  }

  return (
    <Modal
      show={true}
      size="md"
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Get PRO</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5">
        <div className="text-darkblue mb-3">
          <h4 className="font-weight-bold my-0">
            Free upgrade to PRO to get more out of Encl
          </h4>
          <div>90 days free. No credit card needed.</div>
        </div>
        <div className="text-darkgray">
          <ul className={style['tick-ul']}>
            <li>Send up to 10GB per transfer</li>
            <li>Choose when transfers expire</li>
            <li>Transfer to unlimited people</li>
            <li>Custom branding</li>
            <li>Access tracking</li>
          </ul>
        </div>
        <div className="d-flex justify-content-center">
          <GoogleSignInButton onClick={signIn} />
        </div>
        <div className="my-2 text-center">
          <a className="text-darkgray" href="https://about.encl.io/#Pricing">Learn more</a>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default LoginPane
