import { useSelector } from 'react-redux'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'

import { uploaderSelector } from '@/stores/uploader'
import { getCurrentUploader } from '@/core'
import { logEvent } from '@/utils'

import style from './style'

const DropZone = () => {
  const state = useSelector(uploaderSelector)

  const [{ canDrop }, dropZone] = useDrop({
    accept: [NativeTypes.FILE],
    drop (item, monitor) {
      const entries = Array.from(monitor.getItem().items).map(item => item.webkitGetAsEntry())
      entries.forEach(async entry => {
        let type
        if (entry.isFile) {
          type = 'file'
        } else if (entry.isDirectory) {
          type = 'directory'
        } else {
          throw new Error('entry type is unknown')
        }
        try {
          await getCurrentUploader().addFileSystemEntry(entry)
        } catch (e) {
          const uploaderState = getCurrentUploader().state
          console.error(e)
          if (uploaderState && uploaderState.error) {
            logEvent("Encl_addFileError", {
              error: e.message
            })
          } else {
            throw e
          }
        }
        logEvent("Encl_addItem", {
          type,
          method: 'drop'
        })
      })
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
    }),
    canDrop () {
      return !state.isFinalized && !state.error
    }
  })

  const isActive = canDrop
  let overlay
  if (isActive) {
    overlay = (
      <div className={style['drop-zone-overlay']}>
        <div className={`d-flex justify-content-center align-items-center ${style['background']}`}>Drop files or folders here</div>
      </div>
    )
  }

  return (
    <div className={style['drop-zone']} ref={dropZone}>
      {overlay}
    </div>
  )
}

export default DropZone
